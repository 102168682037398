import type { Alert } from "@/models/alerts";
import type { Currency } from "@/models/currency";
import { Spinner } from "@/share/Spinner/Spinner";
import type { AlertItem } from "@/store/state/alerts/alerts.reducer";
import { getIsSameLimitPrice } from "@/utils/alerts";
import { DATE_LONG_FORMAT, useFormattingContext } from "@/utils/format";
import { formatDateLocalized } from "@/utils/locale";
import { useMatomo } from "@/utils/useMatomo";
import { parseISO } from "date-fns";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { UncontrolledTooltip } from "reactstrap";

type AlertCardProps = {
  alert: AlertItem;
  startUpdateAlert: (alert: Alert) => void;
  renewAlert: (alert: Alert) => void;
  onDelete: (id: string) => void;
  readonly: boolean;
  liveAlerts: AlertItem[];
};

function DeleteButton({
  disabled,
  onDelete,
  isDeleting,
}: {
  onDelete: () => void;
  isDeleting: boolean;
  disabled: boolean;
}) {
  return (
    <button
      type="button"
      disabled={disabled}
      className="btn btn-outline-primary m-1"
      onClick={onDelete}
    >
      {isDeleting && <Spinner />}
      <FormattedMessage id="alerts.actions.delete" />
    </button>
  );
}

export function AlertCard({
  alert,
  startUpdateAlert,
  renewAlert,
  onDelete,
  readonly,
  liveAlerts,
}: AlertCardProps) {
  const { formatAmountWithoutSign } = useFormattingContext();
  const { trackAction } = useMatomo();

  const [baseCurrency, contraCurrency] = alert.currencyPair.split("/");

  const isSameLimitPrice = getIsSameLimitPrice(
    liveAlerts,
    alert.limitPrice,
    baseCurrency as Currency,
    contraCurrency as Currency
  );

  const isMaxLiveAlerts =
    liveAlerts.filter(
      ({ currencyPair }) =>
        currencyPair === `${contraCurrency}/${baseCurrency}` ||
        currencyPair === `${baseCurrency}/${contraCurrency}`
    ).length > 9;

  const onEditClick = React.useCallback(() => {
    startUpdateAlert(alert);
  }, [alert, startUpdateAlert]);

  const onDeleteClick = React.useCallback(() => {
    onDelete(alert.id);
  }, [alert.id, onDelete]);

  const onRenewClick = React.useCallback(() => {
    trackAction("Start renew alert");
    renewAlert(alert);
  }, [trackAction, alert, renewAlert]);

  return (
    <div
      className={`card card-bordered mt-3 ${
        alert.status !== "Watched" ? "bg-lvl3" : ""
      }`}
      data-e2e="alert-row"
      data-id={alert.id}
    >
      <div className="row m-2">
        <div className="col-md-2 col-sm-6 col-xs-12">
          <div className="text-secondary fw-medium my-1">
            <FormattedMessage id="alerts.blotter.currencyPair" />
          </div>

          <div className="mb-1 fw-bold">
            {alert.currencyPair.replace("/", " / ")}
          </div>
        </div>

        <div className="col-md-2 col-sm-6 col-xs-12">
          <div className="text-secondary fw-medium my-1">
            <FormattedMessage id="alerts.blotter.rate" />
          </div>

          <div className="mb-1">
            {formatAmountWithoutSign(alert.limitPrice)}
          </div>
        </div>

        <div className="col-md-2 col-sm-6 col-xs-12">
          <div className="text-secondary fw-medium my-1">
            <FormattedMessage id="alerts.blotter.expiry" />
          </div>

          <div className="mb-1">
            {alert.goodTillCancelled ? (
              <FormattedMessage id="alerts.blotter.goodTillCancelled" />
            ) : alert.expiryDay ? (
              formatDateLocalized(parseISO(alert.expiryDay), DATE_LONG_FORMAT)
            ) : (
              ""
            )}
          </div>
        </div>

        <div className="col-md-2 col-sm-6 col-xs-12">
          <div className="text-secondary fw-medium my-1">
            <FormattedMessage id="alerts.blotter.creation" />
          </div>

          <div className="mb-1">
            {alert.creationDate
              ? formatDateLocalized(
                  parseISO(alert.creationDate),
                  DATE_LONG_FORMAT
                )
              : ""}
          </div>
        </div>

        {alert.status === "Watched" ? (
          <div className="col-md-4 col-sm-12 text-end">
            <DeleteButton
              onDelete={onDeleteClick}
              isDeleting={alert.isDeleting}
              disabled={readonly}
            />
            <button
              type="button"
              className="btn btn-outline-primary m-1"
              onClick={onEditClick}
            >
              <FormattedMessage id="alerts.actions.edit" />
            </button>
          </div>
        ) : (
          <>
            <div className="col-md-2 col-sm-6 col-xs-12">
              <div className="text-secondary fw-medium my-1">
                <FormattedMessage id="alerts.blotter.status" />
              </div>

              <span className="badge rounded-pill badge-light text-secondary">
                <FormattedMessage
                  id={`alerts.blotter.status.${alert.status}`}
                />
              </span>
            </div>

            <div
              className="col-md-2 col-sm-6 ps-0 text-end"
              id={`${isSameLimitPrice || isMaxLiveAlerts ? "alertAction" : ""}`}
            >
              <button
                type="button"
                className="btn btn-outline-primary m-1"
                disabled={isSameLimitPrice || isMaxLiveAlerts}
                onClick={onRenewClick}
              >
                <FormattedMessage
                  id={
                    alert.status === "Cancelled"
                      ? "alerts.actions.reactivate"
                      : "alerts.actions.renew"
                  }
                />
              </button>
            </div>
            {(isMaxLiveAlerts || isSameLimitPrice) && (
              <UncontrolledTooltip placement="right" target="alertAction">
                <span>
                  <FormattedMessage
                    id={`${
                      isMaxLiveAlerts
                        ? "alerts.maxLiveAlerts"
                        : "alerts.sameLimitPrice"
                    }`}
                  />
                </span>
              </UncontrolledTooltip>
            )}
          </>
        )}
      </div>
    </div>
  );
}
