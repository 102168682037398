import { sendHelpRequest } from '@/services/helpRequest.service';
import { getSgwtConnect } from '@/sgwtConnect';
import type { AppStore } from '@/store/app.state';
import { type AppCrashedAction, createAppCrashedAction } from '@/store/state/appCrash/appCrash.actions';
import { cleanUpSensitiveInformations } from '@/utils/cleanUpSensitiveInformations';
import { http } from '@/utils/http';
import { takeScreenshot } from '@/utils/screenshot';
import { isDefined } from '@sgme/fp';
import { unminifyStackTrace } from '@sgme/stacktrace';
import { actionCreatorTypeExtractor, actionNotifier } from './logActionMiddlewares/notifyActionMiddleware';

export const autosendHelpRequestMiddleware = (isDev: boolean, getStore: () => AppStore) => {
  const actionsList = isDev ? [] : [actionCreatorTypeExtractor(createAppCrashedAction)];

  return actionNotifier({
    didMatch: async (err: AppCrashedAction) => {
      const { error, source } = err;

      const newScreenshot = await takeScreenshot();
      const screenshot = newScreenshot.toDataURL('image/jpeg', 0.1); // LOW Quality img

      let errorMessage = '';
      if (isDefined(error) && typeof error === 'string') {
        errorMessage = error.substring(0, error.indexOf('\n'));
      }
      const handledError = await unminifyStackTrace(error);
      let details = '';
      if (errorMessage !== '' && handledError !== '') {
        details = `Automatic help request sent after APP_CRASHED from: ${source}\n ${errorMessage}\n${handledError}`;
      } else {
        details = `APP_CRASHED unabled to handled correctly the error ${JSON.stringify(err)}`;
      }

      sendHelpRequest(
        http,
        getSgwtConnect(),
      )({
        application: 'SGME-MY-FX',
        config: 'appcrashed',
        details,
        lastActions: JSON.stringify(getStore().getLastActions(), cleanUpSensitiveInformations),
        state: JSON.stringify(getStore().getState()),
        sendConfirmation: false,
        screenshot,
      }).subscribe();
    },
    actionsList,
  });
};
