import type { Alert } from '@/models/alerts';
import type { Currency } from '@/models/currency';
import type { AppState } from '@/store/app.state';
import type { AppDispatch } from '@/store/store';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { parseISO } from 'date-fns';
import { createAlertFormUpdate, updateAlertFormId } from './create-alert.actions';
import { DEFAULT_CREATE_ALERT_STATE } from './create-alert.reducer';

export const createAlertChangeCurrencyThunk = createAsyncThunk<void, { way: 'base' | 'contra'; currency: Currency }, { dispath: AppDispatch; state: AppState }>(
  'createAlertChangeCurrencyThunk',
  ({ way, currency }, thunkAPI) => {
    const { createAlert } = thunkAPI.getState();

    if ((way === 'base' && currency === createAlert.data.contraCurrency) || (way === 'contra' && currency === createAlert.data.baseCurrency)) {
      // user is swapping currencies
      thunkAPI.dispatch(
        createAlertFormUpdate({
          baseCurrency: createAlert.data.contraCurrency,
          contraCurrency: createAlert.data.baseCurrency,
        }),
      );
    } else {
      thunkAPI.dispatch(createAlertFormUpdate(way === 'base' ? { baseCurrency: currency, limitPrice: '' } : { contraCurrency: currency, limitPrice: '' }));
    }
  },
);

const DEFAULT_BASE_CURRENCY: Currency = 'USD';
const DEFAULT_CONTRA_CURRENCY: Currency = 'EUR';

export const startCreateAlertThunk = createAsyncThunk<void, void, { dispath: AppDispatch; state: AppState }>('startCreateAlertThunk', (_, thunkAPI) => {
  const state = thunkAPI.getState();
  const currencies = state.user.selectedCompany?.rfsCurrencies!;

  const baseCurrency = currencies.find((c) => c === DEFAULT_BASE_CURRENCY) ?? currencies.find((c) => c !== DEFAULT_BASE_CURRENCY);

  const contraCurrency = currencies.find((c) => c === DEFAULT_CONTRA_CURRENCY) ?? currencies.find((c) => c !== baseCurrency);

  thunkAPI.dispatch(
    createAlertFormUpdate({
      ...DEFAULT_CREATE_ALERT_STATE.data,
      baseCurrency,
      contraCurrency,
    }),
  );
});

export const startUpdateAlertThunk = createAsyncThunk<void, Alert, { dispath: AppDispatch; state: AppState }>('startUpdateAlertThunk', (alert, thunkAPI) => {
  thunkAPI.dispatch(updateAlertFormId(alert.id));
  thunkAPI.dispatch(createAlertFormUpdate(toAlertForm(alert)));
});

export const renewAlertThunk = createAsyncThunk<void, Alert, { dispath: AppDispatch; state: AppState }>('renewAlertThunk', (alert, thunkAPI) => {
  thunkAPI.dispatch(updateAlertFormId(alert.id));

  let alertForm = toAlertForm(alert);

  if (alert.status === 'Expired') {
    alertForm = { ...alertForm, expiryDate: null };
  }

  thunkAPI.dispatch(createAlertFormUpdate(alertForm));
});

const toAlertForm = (alert: Alert) => {
  const [contraCurrency, baseCurrency] = alert.currencyPair.split('/') ?? [DEFAULT_BASE_CURRENCY, DEFAULT_CONTRA_CURRENCY];

  return {
    baseCurrency: baseCurrency as Currency,
    contraCurrency: contraCurrency as Currency,
    limitPrice: String(alert.limitPrice),
    expiryDate: alert.goodTillCancelled ? null : parseISO(alert.expiryDay),
  };
};
