import { crashLogger, logger } from '@/logging/logger';
import { AppState } from '@/store/app.state';
import { AppCrashedAction } from '@/store/state/appCrash/appCrash.actions';
import { AddErrorAction } from '@/store/state/errors/errors.actions';
import { AddTradeCaptureErrorAction, SpotDateErrorAction } from '@/store/state/tradeCapture/tradeCapture.actions';
import { Action } from '@reduxjs/toolkit';
import { unminifyStackTrace } from '@sgme/stacktrace';
import { OnActionNotifierCallback } from './notifyActionMiddleware';

type ActionWithErrorStack = AppCrashedAction | AddTradeCaptureErrorAction | SpotDateErrorAction | AddErrorAction

export const logActionCallback: OnActionNotifierCallback<AppState> = async (action: Action) => {
  if (isErrorAction(action)) {
    const customError = await unminifyStackTrace(action.error);
    crashLogger.logError('[ERROR] action: {type_s}, message: {message_s}', action.type, customError)
    return;
  }

  logger.logInformation(`${action.type} {action}`, JSON.stringify(action)); // we log info action only to elastic search, not to the console.
  return;
};

/**
 * method to log only action type - prevent for to large data
 * @param action {action}
 */
export const resizedLogActionCallback: OnActionNotifierCallback<AppState> = (action: Action) => {
  const resizedAction = { type: action.type };
  logger.logInformation(resizedAction.type);
};

export function isErrorAction(action: any): action is ActionWithErrorStack {
  return 'error' in action;
 }

