import type { ForwardTrade } from '@/models/trade';
import type { AppAction } from '@/store/app.actions';
import type { Reducer } from '@reduxjs/toolkit';

export interface RolloverState {
  trade: ForwardTrade | undefined;
}

export const DEFAULT_ROLLOVER_STATE: RolloverState = { trade: undefined };

export const rolloverReducer: Reducer<RolloverState, AppAction> = (
  // biome-ignore lint/style/useDefaultParameterLast: will be fixed with RTK conversion
  state = DEFAULT_ROLLOVER_STATE,
  action,
): RolloverState => {
  switch (action.type) {
    case 'START_ROLLOVER':
      return { trade: action.trade };

    case 'RESET':
      return { trade: undefined };

    default:
      return state;
  }
};
