import type { AppError } from '@/models/AppError';
import type { AppAction } from '@/store/app.actions';
import type { Reducer } from '@reduxjs/toolkit';

export const DEFAULT_ERRORS_STATE: AppError[] = [];

export const errorsReducer: Reducer<AppError[], AppAction> = (
  // biome-ignore lint/style/useDefaultParameterLast: will be fixed with RTK conversion
  state = DEFAULT_ERRORS_STATE,
  action,
): AppError[] => {
  switch (action.type) {
    case 'ADD_ERROR':
      return [...state, action.error];

    case 'CLOSE_ERROR':
      return state.filter((_, idx) => idx !== action.errorIndex);

    case 'CLOSE_LAST_ERROR':
    case 'STREAM_REQUESTED':
      return state.filter((_, idx) => idx !== state.length - 1);

    default:
      return state;
  }
};
