import type { Feature } from '@/config/app.config';
import type { Company, CurrentUser } from '@/models/user';
import type { Action } from '@reduxjs/toolkit';
import type { AccountCenterNavigateAsItem } from '@sgwt/sgwt-widgets-react';

export type UserAction =
  | UserLoadedAction
  | NavigableAsUsersLoadedAction
  | ChangeCompanyAction
  | NavigateAsUserAction
  | StartNavigateAsAction
  | NavigateAsUserFailedAction
  | DisconnectAction;

export type StartNavigateAsAction = Action<'START_NAVIGATE_AS'> & { email: string };

export const startNavigateAsAction = (email: string): StartNavigateAsAction => ({
  type: 'START_NAVIGATE_AS',
  email,
});

export type UserLoadedAction = Action<'USER_LOADED'> & {
  user: CurrentUser & { featureToggles: Record<Feature, boolean> };
};
export const userLoadedAction = (user: CurrentUser & { featureToggles: Record<Feature, boolean> }): UserLoadedAction => ({
  type: 'USER_LOADED',
  user,
});

type NavigableAsUsersLoadedAction = Action<'NAVIGABLE_AS_USERS_LOADED'> & {
  users: AccountCenterNavigateAsItem[];
};
export const navigableAsUsersLoadedAction = (users: AccountCenterNavigateAsItem[]): NavigableAsUsersLoadedAction => ({
  type: 'NAVIGABLE_AS_USERS_LOADED',
  users,
});

export type ChangeCompanyAction = Action<'CHANGE_COMPANY'> & {
  company: Company;
};
export const changeCompanyAction = (company: Company): ChangeCompanyAction => ({
  type: 'CHANGE_COMPANY',
  company,
});

type NavigateAsUserAction = Action<'NAVIGATE_AS_USER'> & {
  user: CurrentUser | undefined;
};
export const navigateAsUserAction = (user: CurrentUser | undefined): NavigateAsUserAction => ({
  type: 'NAVIGATE_AS_USER',
  user,
});

type NavigateAsUserFailedAction = Action<'NAVIGATE_AS_USER_FAILED'>;

export const navigateAsUserFailedAction = (): NavigateAsUserFailedAction => ({
  type: 'NAVIGATE_AS_USER_FAILED',
});

export type LogOffReason = 'connected_twice' | 'force_logoff_time';
type DisconnectAction = Action<'NOTIFY_DISCONNECTION'> & { logoffReason: LogOffReason };

export const disconnectAction = (logoffReason: LogOffReason): DisconnectAction => ({
  type: 'NOTIFY_DISCONNECTION',
  logoffReason,
});
