import { ServiceLoader } from '@sgme/ui';
import { type AcrValue, SgwtConnect, SgwtWebAnalytics, SgwtWidgetContextProvider, useSgwtWidgets } from '@sgwt/sgwt-widgets-react';
import type { Infrastructure } from '@sgwt/sgwt-widgets-react/dist/utils';
import { type PropsWithChildren, useState } from 'react';

type SgwtContainerProps = {
  infrastructure: Infrastructure;
  sgConnect: {
    cliendId: string;
    endpoint: string;
    redirectUri?: string;
    scope: string;
    acrValues?: string;
  };

  sgAnalytics?: {
    siteId: string;
    url: string;
  };
};

export const SgwtContainer = (props: PropsWithChildren<SgwtContainerProps>) => {
  const { infrastructure, sgConnect, sgAnalytics, children } = props;

  const [isReady, setIsReady] = useState(false);

  return (
    <SgwtWidgetContextProvider infrastructure={infrastructure}>
      <SgwtConnect
        clientId={sgConnect.cliendId}
        authorizationEndpoint={sgConnect.endpoint}
        redirectUri={sgConnect.redirectUri}
        scope={sgConnect.scope}
        acrValues={sgConnect.acrValues as AcrValue | undefined}
        onWidgetReady={() => setIsReady(true)}
      />

      {sgAnalytics !== undefined && <SgwtWebAnalytics siteId={sgAnalytics.siteId} baseUrl={sgAnalytics.url} />}

      {isReady && <AuthorizedApp>{children}</AuthorizedApp>}
    </SgwtWidgetContextProvider>
  );
};

const AuthorizedApp = (props: PropsWithChildren) => {
  const { children } = props;

  const { sgwtConnect } = useSgwtWidgets();

  // the signature shows a not nullable value (sgwtConnect) but it can be undefined
  if (sgwtConnect?.sgwtConnect === undefined) {
    return <ServiceLoader />;
  }

  if (!sgwtConnect.sgwtConnect.isAuthorized()) {
    const authorizationError = sgwtConnect.sgwtConnect.getAuthorizationError();

    if (authorizationError == null) {
      sgwtConnect.sgwtConnect.requestAuthorization();

      return <ServiceLoader />;
    }
  }

  return children;
};
