import { FormattedMessage } from "react-intl";
import { isWithinInterval, parseISO, startOfDay } from "date-fns";
import { isDefined } from "@sgme/fp";
import { useNavigate } from "react-router";
import type { MyFxFlow } from "@/store/state/upcomingFlows/upcomingFlows.models";
import { useFormattingContext } from "@/utils/format";
import { FeatureToggle } from "@/share/FeatureToggle/FeatureToggle";
import { TradeButton } from "@/App/Trades/TradesList/TradeButton";
import { isSameOrBefore } from "@/utils/dates";
import {
  startFlowPredeliverThunk,
  startFlowRolloverThunk,
} from "@/store/state/rfs/rfs.thunks";
import { useSpotDatesQuery } from "@/store/state/upcomingFlows/upcomingFlows.api";
import type { ForwardTrade, MyHedgeTrade } from "@/models/trade";
import { getToggleNameFromInstrument } from "@/App/utils/featureToggle";
import { selectFeatureToggleActive } from "@/store/state/ui/ui.selectors";
import { routes } from "@/App/routes";
import { tradesSelectedIdAction } from "@/store/state/trades/trades.actions";
import { useAppDispatch, useAppSelector } from "@/store/store";
import { selectHasSwapRight } from "@/store/state/user/user.selectors";

interface FlowLegProps {
  firstFlow: MyFxFlow & MyHedgeTrade;
  secondFlow: MyFxFlow & MyHedgeTrade;
}

export default function FlowLeg({ firstFlow: flow, secondFlow }: FlowLegProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const canSeeActionButtons = useAppSelector(selectHasSwapRight);

  const { mapFlow, formatAmountWithPrecision } = useFormattingContext();
  const {
    paymentDate,
    contractId,
    productName,
    amount,
    isCertain: firstIsCertain,
    currency: firstCurrency,
    twoDaysBeforePaymentDate,
  } = mapFlow(flow);

  const {
    amount: SecondFlowAmount,
    isCertain: secondIsCertain,
    currency: secondCurrency,
  } = mapFlow(secondFlow);

  const strike =
    isDefined(flow.amount) && isDefined(secondFlow.amount)
      ? formatAmountWithPrecision(
          Math.abs(flow.amount) / Math.abs(secondFlow.amount),
          5
        )
      : "-";

  const isCertain = secondIsCertain || firstIsCertain;

  const { data: spotDates } = useSpotDatesQuery([flow.currencyPair ?? ""]);
  const spotDate = spotDates?.[flow.currencyPair ?? ""];
  const isExpiring =
    !!spotDate &&
    isSameOrBefore(
      startOfDay(parseISO(flow.maturitydate)),
      startOfDay(parseISO(spotDate))
    );
  const isEmpty =
    flow.remainingAmount === null ||
    flow.remainingAmount === undefined ||
    flow.remainingAmount === 0;
  const isRolloverReady =
    isDefined(spotDate) &&
    isWithinInterval(startOfDay(parseISO(flow.maturitydate)), {
      start: startOfDay(new Date()),
      end: startOfDay(parseISO(spotDate)),
    });

  const toggleName = getToggleNameFromInstrument(flow.instrumentName);

  const isFeatureActive = useAppSelector((state) =>
    selectFeatureToggleActive(state, toggleName)
  );

  const displayCalendarButton = (instrumentName: string) =>
    [
      "FxoForwardAccumulator",
      "FxoTargetAccumulator",
      "FxoSquareTargetAccumulator",
      "FxoPauseTargetAccumulator",
      "FxoVanillaAccumulator",
    ].includes(instrumentName);

  function onOpenCalendar(reference: string) {
    navigate(routes.CALENDAR);
    dispatch(tradesSelectedIdAction(reference));
  }

  const onClickPredeliver = async (clickedFlow: MyFxFlow & ForwardTrade) => {
    await dispatch(startFlowPredeliverThunk(clickedFlow));
    navigate(routes.USE_ENVELOPPE);
  };

  const onClickStartFlowRollover = async (
    clickedFlow: MyFxFlow & ForwardTrade
  ) => {
    await dispatch(startFlowRolloverThunk(clickedFlow));
    navigate(routes.REPORT_ENVELOPPE);
  };

  const disableReason = !canSeeActionButtons
    ? "noRight"
    : isEmpty
    ? "empty"
    : undefined;

  return (
    <tr>
      <td className="align-middle">{paymentDate}</td>

      <td className="align-middle">{contractId}</td>
      <td className="align-middle fw-semibold">
        {productName} &nbsp;
        {!isCertain && (
          <>
            <span
              className="badge badge-discreet-secondary rounded-pill"
              data-e2e="is-certain-badge"
            >
              <FormattedMessage id="app.portfolio.upcomingFlows.detailed.table.UNCERTAIN" />
            </span>
            &nbsp;
          </>
        )}
      </td>
      <td>
        <p className={`mb-0`} data-e2e="first-side">
          <FormattedMessage
            id="app.portfolio.upcomingFlows.detailed.table.WAY"
            values={{ way: flow.side }}
          />
        </p>
        <p className={`text-secondary mb-0`} data-e2e="second-side">
          <FormattedMessage
            id="app.portfolio.upcomingFlows.detailed.table.WAY"
            values={{ way: secondFlow.side }}
          />
        </p>
      </td>
      <td className="text-end">
        <p className={`mb-0`} data-e2e="first-amount">
          {amount}
        </p>
        {SecondFlowAmount !== undefined && (
          <p className={`text-secondary mb-0`} data-e2e="second-amount">
            {SecondFlowAmount}
          </p>
        )}
      </td>
      <td className="text-end">
        <p className={`mb-0`} data-e2e="first-currency">
          {firstCurrency}
        </p>
        <p className={`text-secondary mb-0`} data-e2e="second-currency">
          {secondCurrency}
        </p>
      </td>
      <td className=" align-middle text-end">{strike}</td>
      <td className="align-middle">
        {flow.instrumentName === "FxOutright" && (
          <div className="d-flex gap-1">
            <FeatureToggle feature="predeliver">
              {(active) =>
                active && !isExpiring ? (
                  <TradeButton
                    type="predeliver"
                    onClick={() => onClickPredeliver(flow)}
                    reference={contractId}
                    disabledReason={disableReason}
                  />
                ) : (
                  <TradeButton
                    type="predeliver"
                    reference={contractId}
                    disabledReason={reasonWhyPredeliverDisabled(
                      active,
                      isEmpty,
                      canSeeActionButtons
                    )}
                  />
                )
              }
            </FeatureToggle>
            <FeatureToggle feature="rollover">
              {(active) =>
                active && isRolloverReady ? (
                  <TradeButton
                    type="rollover"
                    twoDaysBeforePaymentDate={twoDaysBeforePaymentDate}
                    onClick={() => onClickStartFlowRollover(flow)}
                    disabledReason={disableReason}
                    reference={contractId}
                  />
                ) : (
                  <TradeButton
                    type="rollover"
                    twoDaysBeforePaymentDate={twoDaysBeforePaymentDate}
                    reference={contractId}
                    disabledReason={reasonWhyRolloverDisabled(
                      active,
                      isEmpty,
                      canSeeActionButtons
                    )}
                  />
                )
              }
            </FeatureToggle>
          </div>
        )}
        {displayCalendarButton(flow.instrumentName) && (
          <TradeButton
            type="calendar"
            reference={flow.reference}
            disabledReason={!isFeatureActive ? "availableSoon" : undefined}
            onClick={() => onOpenCalendar(flow.reference)}
          />
        )}
      </td>
    </tr>
  );
}

const reasonWhyPredeliverDisabled = (
  active: boolean,
  isEmpty: boolean,
  canSeeActionButtons: boolean
) => {
  if (!canSeeActionButtons) return "noRight";
  if (!active) return "availableSoon";
  if (isEmpty) return "empty";
  return "expiring";
};
const reasonWhyRolloverDisabled = (
  active: boolean,
  isEmpty: boolean,
  canSeeActionButtons: boolean
) => {
  if (!canSeeActionButtons) return "noRight";
  if (!active) return "availableSoon";
  if (isEmpty) return "empty";
  return "expiring";
};
