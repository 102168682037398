import { piwikPages } from '@/App/routes';
import { noop } from '@/utils/noop';
import { useSgwtWidgets } from '@sgwt/sgwt-widgets-react';
import { type PropsWithChildren, createContext, useCallback, useContext, useEffect, useMemo, useRef } from 'react';

type PerPageAction =
  | 'Opened page'
  | 'Changed counterparty'
  | 'Changed buy currency'
  | 'Changed sell currency'
  | 'Switched currencies'
  | 'Searched an operation'
  | 'Filtered operations'
  | 'Triggered execution'
  | 'Triggered contact'
  | 'Changed buy amount'
  | 'Changed sell amount'
  | 'Changed value date'
  // widgets
  | 'Opened help center'
  | 'Closed help center'
  | 'Opened notification center'
  | 'Closed notification center'
  | 'Opened my services board'
  | 'Closed my services board'
  | 'Opened account center'
  | 'Closed account center'
  | 'Start renew alert'
  | 'Created alert'
  | 'Updated alert'
  | 'Deleted alert'
  | 'Renewed alert'
  | 'Gone to sellOrBuy from trades'
  | 'Gone to sellOrBuy from upcoming flows'
  | 'Gone to alerts from buySell page';

type RfqCategory = 'RFQs' | 'Execution success' | 'Execution abandoned' | 'Execution errors';

type RfqAction =
  | 'Started RFQ'
  | 'Executed spot'
  | 'Executed forward'
  | 'Executed predeliver on forward'
  | 'Executed rollover on forward'
  | 'Abandoned spot'
  | 'Abandoned forward'
  | 'Abandoned predeliver on forward'
  | 'Abandoned rollover on forward'
  | 'Reached fat finger limit'
  | 'Has been refused to trade bc no CVAR limit defined'
  | 'Reached CVAR limit'
  | 'Reached RDL limit'
  | 'Reached RFQ timeout'
  | 'Reached cut-off'
  | 'Faced an unknown error when triggered execution'
  | 'Go home after Rfq timeout'
  | 'Continue after Rfq timeout';

export interface MatomoEventBase {
  category: string;
  action: string;
  name?: string;
  value?: string; // this can only be a number
}

export type MatomoContextValue = {
  trackAction: TrackAction;
  trackRfq: TrackRfq;
  updateSelectedCompanyName: UpdateSelectedCompanyName;
};

export type TrackAction = (action: PerPageAction) => void;
export type TrackRfq = (category: RfqCategory, action: RfqAction) => void;
export type UpdateSelectedCompanyName = (name: string) => void;

export const RawMatomoContext = createContext<MatomoContextValue>({ trackAction: noop, trackRfq: noop, updateSelectedCompanyName: noop });

export const MatomoContext = ({ children }: PropsWithChildren) => {
  // we don't want to trigger a rerender of the component which uses this context
  // so sgwtWebAnalytics is stored in a reference and trackEvent function call the current value of this reference
  // and is not recreated each time the sgwtWebAnalytics is updated
  // to avoid to rerender all components that use this context
  const { sgwtWebAnalytics } = useSgwtWidgets();
  const sgwtWebAnalyticsRef = useRef(sgwtWebAnalytics);

  useEffect(() => {
    sgwtWebAnalyticsRef.current = sgwtWebAnalytics;
  }, [sgwtWebAnalytics]);

  // same technic to avoid to rerender all components that use this context
  const selectedCompanyName = useRef('');

  const setSelectedCompanyName = useMemo(
    () => (value: string) => {
      selectedCompanyName.current = value;
    },
    [],
  );

  // loggers
  const trackEvent = useCallback((event: MatomoEventBase) => {
    sgwtWebAnalyticsRef.current?.trackEvent(event.category, event.action, event.name, event.value);
  }, []);

  const trackAction = useCallback(
    (action: PerPageAction) => {
      const event: MatomoEventBase = {
        category: piwikPages[window.location.pathname as keyof typeof piwikPages],
        action,
        name: selectedCompanyName.current,
      };

      trackEvent(event);
    },
    [trackEvent],
  );

  const trackRfq = useCallback(
    (category: RfqCategory, action: RfqAction) => {
      trackEvent({ category, action, name: selectedCompanyName.current });
    },
    [trackEvent],
  );

  const context: MatomoContextValue = useMemo(
    () => ({
      trackAction,
      trackRfq,
      updateSelectedCompanyName: setSelectedCompanyName,
    }),
    [trackAction, trackRfq, setSelectedCompanyName],
  );

  return <RawMatomoContext.Provider value={context}>{children}</RawMatomoContext.Provider>;
};

export const useMatomo = () => useContext(RawMatomoContext);
