import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { routes } from "@/App/routes";
import type { CreateAlertActionStatus } from "@/store/state/create-alert/create-alert.model";
import { Spinner } from "@/share/Spinner/Spinner";
import { UncontrolledTooltip } from "reactstrap";

type CreateButtonListProps = {
  canSubmit: boolean;
  createAlert: () => void;
  currently: CreateAlertActionStatus;
  readonly: boolean;
  isMaxLiveAlerts: boolean;
};

export function CancelButton() {
  return (
    <Link
      to={{ pathname: routes.ALERTS, search: window.location.search }}
      className="btn btn-flat-secondary btn-block-xs-only me-2"
    >
      <FormattedMessage id="alerts.cancel" />
    </Link>
  );
}

function DeleteButton({
  onClick,
  disabled,
  currently,
}: {
  onClick: () => void;
  currently: CreateAlertActionStatus;
  disabled: boolean;
}) {
  return (
    <button
      type="button"
      className="btn btn-discreet-danger btn-block-xs-only me-2"
      onClick={onClick}
      disabled={disabled}
    >
      {currently === "Deleting" && <Spinner />}
      <FormattedMessage id="alerts.deleteAlertButton" />
    </button>
  );
}

export function CreateButtonList({
  canSubmit,
  createAlert,
  currently,
  readonly,
  isMaxLiveAlerts,
}: CreateButtonListProps) {
  return (
    <div className="col-lg-6 col-sm-12 mt-5">
      <div
        className="form-group"
        id={`${isMaxLiveAlerts ? "createButton" : ""}`}
      >
        <button
          type="button"
          disabled={canSubmit === false || readonly || isMaxLiveAlerts}
          onClick={createAlert}
          className="btn btn-primary btn-block-xs-only me-2"
          data-e2e="create-alert-submit-btn"
        >
          {currently === "Creating" && <Spinner />}
          <FormattedMessage id="alerts.createAlertButton" />
        </button>
        <CancelButton />
        {isMaxLiveAlerts && (
          <UncontrolledTooltip placement="left" target="createButton">
            <span>
              <FormattedMessage id="alerts.maxLiveAlerts" />
            </span>
          </UncontrolledTooltip>
        )}
      </div>
    </div>
  );
}

type UpdateButtonListProps = {
  canSubmit: boolean;
  updateAlert: () => void;
  deleteAlert: () => void;
  currently: CreateAlertActionStatus;
  readonly: boolean;
};

export function UpdateButtonList({
  canSubmit,
  readonly,
  updateAlert,
  currently,
  deleteAlert,
}: UpdateButtonListProps) {
  return (
    <div className="col-lg-6 col-sm-12 mt-5">
      <div className="form-group">
        <button
          type="button"
          disabled={canSubmit === false || readonly}
          onClick={updateAlert}
          className="btn btn-primary btn-block-xs-only me-2"
          data-e2e="create-alert-submit-btn"
        >
          {currently === "Updating" && <Spinner />}
          <FormattedMessage id="alerts.updateAlertButton" />
        </button>
        <DeleteButton
          onClick={deleteAlert}
          currently={currently}
          disabled={readonly}
        />
        <CancelButton />
      </div>
    </div>
  );
}
