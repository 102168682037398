import type { AppAction } from '@/store/app.actions';
import type { Reducer } from '@reduxjs/toolkit';
import { type TradesState, tradeAdapter } from './trades.model';

export const DEFAULT_TRADES_STATE: TradesState = { trades: { status: 'fetching' }, spotDates: {} };

export const tradesReducer: Reducer<TradesState, AppAction> = (
  // biome-ignore lint/style/useDefaultParameterLast: will be fixed with RTK conversion
  state = DEFAULT_TRADES_STATE,
  action,
): TradesState => {
  switch (action.type) {
    case 'CHANGE_COMPANY':
      return { trades: { status: 'fetching' }, spotDates: state.spotDates };

    case 'TRADES_RECEIVED':
      return {
        trades: {
          status: 'received',
          data: tradeAdapter.addMany(tradeAdapter.getInitialState(), action.trades),
        },
        spotDates: state.spotDates,
      };

    case 'TRADES_REQUEST_FAILED':
      return {
        trades: {
          status: 'error',
        },
        spotDates: state.spotDates,
      };

    case 'REFRESH_TRADE':
      return {
        trades: {
          status: 'fetching',
        },
        spotDates: state.spotDates,
      };

    case 'SPOT_DATES_RECEIVED':
      return {
        trades: state.trades,
        spotDates: {
          ...state.spotDates,
          ...action.spotDates,
        },
      };

    case 'TRADE_SELECTED':
      if (state.trades.status === 'received') {
        return {
          ...state,
          trades: { ...state.trades, selectedId: action.selectedTradeId },
        };
      }
      return { ...state };

    default:
      return state;
  }
};
