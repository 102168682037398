import { isAlertFormPage, isAlertFormPathname } from '@/App/routes';
import { createAlertFormUpdate } from '@/store/state/create-alert/create-alert.actions';
import { DEFAULT_CREATE_ALERT_STATE } from '@/store/state/create-alert/create-alert.reducer';
import { type Epic, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { filter, switchMap, withLatestFrom } from 'rxjs/operators';
import type { AppAction } from '../app.actions';
import type { AppState } from '../app.state';
import type { LocationChangeAction } from '../state/rfs/rfs.actions';

export const cleanFormEpic = (): Epic<AppAction, AppAction, AppState> => (action$, state$) =>
  action$.pipe(
    ofType<AppAction, LocationChangeAction>('LOCATION_CHANGE'),
    withLatestFrom(state$),
    filter(([action, state]) => isAlertFormPage(action.pathname) === false && (state.ui.router.previous ? isAlertFormPathname(state.ui.router.previous) : false)),
    switchMap(() => of(createAlertFormUpdate(DEFAULT_CREATE_ALERT_STATE.data))),
  );
