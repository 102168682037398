import { routes } from '@/App/routes';
import { getNewRfsId } from '@/store/epics/request-for-stream.epic';
import { requestForStreamAction, resetAction } from '@/store/state/rfs/rfs.actions';
import { selectRfsStatus } from '@/store/state/rfs/rfs.selectors';
import { useAppDispatch } from '@/store/store';
import { useMatomo } from '@/utils/useMatomo';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

export function InactivityTraderModal() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { trackRfq } = useMatomo();

  const isOpen = useSelector(selectRfsStatus) === 'quoting-timeout';

  const onClose = () => {
    trackRfq('RFQs', 'Go home after Rfq timeout');
    dispatch(resetAction());
    navigate(routes.HOME);
  };
  const onContinue = () => {
    trackRfq('RFQs', 'Continue after Rfq timeout');
    dispatch(requestForStreamAction(getNewRfsId()));
  };

  return (
    <Modal isOpen={isOpen} className="modal-dialog-centered" contentClassName="shadow-max border border-warning" data-e2e="rfs-timeout-modal">
      <ModalHeader>
        <div className="d-flex flex-column w-100">
          <div className="rounded-circle bg-warning-opacity-10 d-flex align-items-center justify-content-center" style={{ width: '3rem', height: '3rem' }}>
            <i className="icon icon-xs text-warning line-height-1"> alarm </i>
          </div>
        </div>
      </ModalHeader>

      <ModalBody>
        <h3 className="modal-title text-warning mb-2 pt-1">
          <FormattedMessage id="trader.timeoutModal.title" />
        </h3>
        <p className="text-large text-secondary mb-4">
          <FormattedMessage id="trader.timeoutModal.description" />
        </p>
        <p className="text-large">
          <FormattedMessage id="trader.timeoutModal.description2" />
        </p>
      </ModalBody>

      <ModalFooter className="modal-footer d-flex justify-content-right">
        <button type="button" className="btn btn-lg btn-flat-secondary" onClick={onClose} data-e2e="btn-go-home">
          <FormattedMessage id="trader.timeoutModal.goBackHome" />
        </button>
        <button type="button" className="btn btn-lg btn-warning" onClick={onContinue} data-e2e="btn-continue">
          <FormattedMessage id="trader.timeoutModal.continue" />
        </button>
      </ModalFooter>
    </Modal>
  );
}
