import type { MyHedgeTrade } from '@/models/trade';
import { useFormattingContext } from '@/utils/format';
import { isSameDay, isToday } from 'date-fns';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

export function LastPill({trades}: {trades: MyHedgeTrade[]}) {
  const { mapTradeWithOptions } = useFormattingContext();

  const lastTrade = trades?.reduce(
    (foundLastTrade, hedgeTrade) => {
      return (
        // no previous one, so take the current hedgeTrade
        foundLastTrade === undefined
        // hedgeTrade is newer
        || (
          new Date(foundLastTrade.date) < new Date(hedgeTrade.date)
          && isToday(new Date(hedgeTrade.date))
        )
      )
        ? hedgeTrade
        : foundLastTrade;
    },
  );

  const mappedTrade = mapTradeWithOptions(lastTrade, { noSign: true });

  return isSameDay(new Date(lastTrade.date), Date.now()) ? (
    <span className="badge rounded-pill badge-discreet-info mt-1 mb-2" data-e2e="latest-pill">
      <FormattedMessage id="home.blotter.last" values={{ ...mappedTrade }} />
    </span>
  ) : null;
}
