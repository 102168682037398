import { addBusinessDays, endOfDay, isSameDay, parseISO } from "date-fns";
import { catchError, filter } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { Currency } from "@/models/currency";
import { ForwardTrade } from "@/models/trade";
import { MasterAmount, Way, wayToMasterAmount } from "@/models/way";
import { startPredeliverAction } from "../predeliver/predeliver.actions";
import { startRolloverAction } from "../rollover/rollover.actions";
import {
  formChangeAction,
  stopStreamAction,
  isAllAvailableUsedChangeAction,
} from "./rfs.actions";
import { getBySellCurrencies } from "../trades/trades.selectors";
import type { AppState } from "@/store/app.state";
import { getClosedDates } from "@/services/trade.service";
import type { MyFxFlow } from "../upcomingFlows/upcomingFlows.models";
import { createAsyncThunk } from "@reduxjs/toolkit";
import type { AppDispatch } from "@/store/store";

// TODO: many of these next thunk can be removed
// dispatch directly the actions !
export const amountChangeThunk = createAsyncThunk<
  void,
  { way: Way; amount: number },
  { dispath: AppDispatch; state: AppState }
>("amountChangeThunk", ({ way, amount }, thunkAPI) => {
  way === "buy"
    ? thunkAPI.dispatch(formChangeAction({ buyAmount: amount, sellAmount: 0 }))
    : thunkAPI.dispatch(formChangeAction({ sellAmount: amount, buyAmount: 0 }));
});

export const amountChangingThunk = createAsyncThunk<
  void,
  void,
  { dispath: AppDispatch; state: AppState }
>("", (_, thunkAPI) => {
  thunkAPI.dispatch(stopStreamAction());
});

export const currencyChangeThunk = createAsyncThunk<
  void,
  { way: Way; currency: Currency },
  { dispath: AppDispatch; state: AppState }
>("currencyChangeThunk", ({ way, currency }, thunkAPI) => {
  const { rfs } = thunkAPI.getState();

  if (
    (way === "buy" && currency === rfs.sellCurrency) ||
    (way === "sell" && currency === rfs.buyCurrency)
  ) {
    // user is swapping currencies
    thunkAPI.dispatch(
      formChangeAction({
        buyCurrency: rfs.sellCurrency,
        sellCurrency: rfs.buyCurrency,
      })
    );
  } else {
    thunkAPI.dispatch(
      formChangeAction(
        way === "buy" ? { buyCurrency: currency } : { sellCurrency: currency }
      )
    );
  }
});

export const swapThunk = createAsyncThunk<
  void,
  void,
  { dispath: AppDispatch; state: AppState }
>("swapThunk", (_, thunkAPI) => {
  const { rfs } = thunkAPI.getState();

  thunkAPI.dispatch(
    formChangeAction({
      buyCurrency: rfs.sellCurrency,
      sellCurrency: rfs.buyCurrency,
      masterAmount:
        rfs.masterAmount === "buyAmount" ? "sellAmount" : "buyAmount",
      sellAmount: rfs.masterAmount === "buyAmount" ? rfs.buyAmount : 0,
      buyAmount: rfs.masterAmount === "sellAmount" ? rfs.sellAmount : 0,
    })
  );
});

export const wayChangeThunk = createAsyncThunk<
  void,
  Way,
  { dispath: AppDispatch; state: AppState }
>("wayChangeThunk", (way, thunkAPI) => {
  const masterAmount = wayToMasterAmountMap[way];

  if (masterAmount !== thunkAPI.getState().rfs.masterAmount) {
    thunkAPI.dispatch(formChangeAction({ masterAmount }));
  }
});

const wayToMasterAmountMap: Record<Way, MasterAmount> = {
  buy: "buyAmount",
  sell: "sellAmount",
};

export const isAllAvailableUsedChangeThunk = createAsyncThunk<
  void,
  boolean,
  { dispath: AppDispatch; state: AppState }
>("isAllAvailableUsedChangeThunk", (isAllAvailableUsed, thunkAPI) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  thunkAPI.dispatch(isAllAvailableUsedChangeAction(isAllAvailableUsed));

  const state = thunkAPI.getState();
  const trade = getDisplayedTrade(state);

  if (trade === undefined) {
    return;
  }

  const amountField = wayToMasterAmount(trade.side);
  const remaining = trade.remainingAmount;

  if (
    isAllAvailableUsed &&
    (state.rfs[amountField] !== remaining ||
      state.rfs.masterAmount !== amountField)
  ) {
    thunkAPI.dispatch(
      formChangeAction({
        [amountField]: remaining,
        masterAmount: amountField,
      })
    );
  }
});

function getDisplayedTrade(state: AppState) {
  return state.predeliver?.trade ?? state.rollover.trade;
}

export const startPredeliverThunk = createAsyncThunk<
  void,
  ForwardTrade,
  { dispath: AppDispatch; state: AppState }
>("startPredeliverThunk", (trade, thunkAPI) => {
  const { buyCurrency, sellCurrency } = getBySellCurrencies(trade);

  thunkAPI.dispatch(
    formChangeAction({
      buyAmount: 0,
      sellAmount: 0,
      buyCurrency,
      sellCurrency,
      postTradeOperation: "Predeliver",
    })
  );

  thunkAPI.dispatch(startPredeliverAction(trade));
});

export const startFlowPredeliverThunk = createAsyncThunk<
  void,
  MyFxFlow & ForwardTrade,
  { dispath: AppDispatch; state: AppState }
>("startFlowPredeliverThunk", (flow, thunkAPI) => {
  const { buyCurrency, sellCurrency } = getBySellCurrencies(flow);

  thunkAPI.dispatch(
    formChangeAction({
      buyAmount: 0,
      sellAmount: 0,
      buyCurrency,
      sellCurrency,
      postTradeOperation: "Predeliver",
    })
  );

  thunkAPI.dispatch(startPredeliverAction(flow));
});

export const startRolloverThunk = createAsyncThunk<
  void,
  ForwardTrade,
  { dispath: AppDispatch; state: AppState }
>("startRolloverThunk", async (trade, thunkAPI) => {
  const { buyCurrency, sellCurrency } = getBySellCurrencies(trade);
  const state = thunkAPI.getState();

  let date = endOfDay(parseISO(trade.maturitydate));

  const closedDates = await getClosedDateFormStateOrApi(
    state,
    trade.currencyPair,
    (c) => getClosedDates(c)
  );

  date = getNextDay(
    date,
    closedDates.map((d) => endOfDay(parseISO(d)))
  );

  thunkAPI.dispatch(
    formChangeAction({
      buyAmount: 0,
      sellAmount: 0,
      buyCurrency,
      sellCurrency,
      date: undefined,
      postTradeOperation: "Rollover",
    })
  );

  thunkAPI.dispatch(startRolloverAction(trade));
});

export async function getClosedDateFormStateOrApi(
  state: AppState,
  currencyPair: string,
  getClosedDatesObs: (c: string) => Observable<string[]>
) {
  if (state.ui.closedDates[currencyPair] !== undefined) {
    return state.ui.closedDates[currencyPair];
  }
  return getClosedDatesObs(currencyPair)
    .pipe(
      filter((closedDate) => closedDate !== undefined && closedDate !== null),
      catchError(() => of([]))
    )
    .toPromise();
}

export function getNextDay(date: Date, closeds: readonly Date[]) {
  let d = date;
  do {
    d = addBusinessDays(d, 1);
    // eslint-disable-next-line no-loop-func
  } while (closeds.find((c) => isSameDay(d, c)));
  return d;
}

export const startFlowRolloverThunk = createAsyncThunk<
  void,
  MyFxFlow & ForwardTrade,
  { dispath: AppDispatch; state: AppState }
>("startFlowRolloverThunk", async (flow, thunkAPI) => {
  const { buyCurrency, sellCurrency } = getBySellCurrencies(flow);
  const state = thunkAPI.getState();

  let date = endOfDay(parseISO(flow.maturitydate));

  const closedDates = await getClosedDateFormStateOrApi(
    state,
    flow.currencyPair,
    (c) => getClosedDates(c)
  );

  date = getNextDay(
    date,
    closedDates.map((d) => endOfDay(parseISO(d)))
  );

  thunkAPI.dispatch(
    formChangeAction({
      buyAmount: 0,
      sellAmount: 0,
      buyCurrency,
      sellCurrency,
      date: undefined,
      postTradeOperation: "Rollover",
    })
  );

  thunkAPI.dispatch(startRolloverAction(flow));
});
