import { getHelpCenter } from '@/App/HelpCenter/HelpCenter';
import { FormattedMessage } from 'react-intl';
import './ContactUs.scss';
import { useMatomo } from '@/utils/useMatomo';
import { useCallback } from 'react';
import { MainLayout } from '../Layout/MainLayout';

const contactUsEmailAddress = 'myfx@sgcib.com';
const contactUsAddress = 'https://myfx.sgmarkets.com/contact';

export function ContactUs() {
  const { trackAction } = useMatomo();

  const sendMessage = useCallback(() => {
    trackAction('Triggered contact');
    getHelpCenter().open();
  }, [trackAction]);

  return (
    <MainLayout>
      <div className="ContactUs card">
        <div className="card-body">
          <h1>
            <FormattedMessage id="contactUs.title" />
          </h1>

          <p>
            <FormattedMessage id="contactUs.description" />
          </p>

          <p>
            <span className="lead">
              <FormattedMessage id="contactUs.tradingDesk" />
            </span>
            <br />
            <a href={contactUsAddress}>Contact</a>
            <br />
            <a href={`mailto:${contactUsEmailAddress}`}>{contactUsEmailAddress}</a>
          </p>
        </div>

        <div className="card-footer">
          <button type="button" className="btn btn-primary" onClick={sendMessage}>
            <FormattedMessage id="contactUs.sendMessage" />
          </button>
        </div>
      </div>
    </MainLayout>
  );
}
