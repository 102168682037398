import type { Company, CurrentUser } from '@/models/user';
import type { Reducer } from '@reduxjs/toolkit';
import type { AccountCenterNavigateAsItem } from '@sgwt/sgwt-widgets-react';
import type { LogOffReason, UserAction } from './user.actions';

export interface BaseUserState {
  currentUser?: CurrentUser;
  navigableAsUsers?: AccountCenterNavigateAsItem[];
  selectedCompany?: Company;
  navigateAsUser?: CurrentUser;
}

export interface InvalidSessionState {
  logoffReason: LogOffReason;
  isValid: false;
}

export interface ValidSessionState {
  logoffReason?: LogOffReason;
  isValid: true;
}

export type UserState = BaseUserState & (InvalidSessionState | ValidSessionState);

export const DEFAULT_USER_STATE: UserState = {
  currentUser: undefined,
  navigableAsUsers: undefined,
  selectedCompany: undefined,
  navigateAsUser: undefined,
  logoffReason: undefined,
  isValid: true,
};

export const userReducer: Reducer<UserState, UserAction> = (
  // biome-ignore lint/style/useDefaultParameterLast: will be fixed with RTK conversion
  state = DEFAULT_USER_STATE,
  action,
): UserState => {
  switch (action.type) {
    case 'USER_LOADED':
      return { ...state, currentUser: action.user, selectedCompany: action.user.companies[0] };

    case 'NAVIGABLE_AS_USERS_LOADED':
      return { ...state, navigableAsUsers: action.users };

    case 'CHANGE_COMPANY':
      return { ...state, selectedCompany: action.company };

    case 'NAVIGATE_AS_USER':
      return { ...state, navigateAsUser: action.user };

    case 'NOTIFY_DISCONNECTION':
      return { ...state, logoffReason: action.logoffReason, isValid: false };

    default:
      return state;
  }
};
