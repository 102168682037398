import { FormattedMessage } from 'react-intl';
import { InstrumentName } from '@/models/trade';
import CalendarTooltip from './CalendarTooltip';

interface CalendarHeadersProps {
  columns: string[];
  accumulatorType: 'permanent' | 'standard' | undefined;
  instrumentName: InstrumentName;
}

export function CalendarTableHeader({ columns, accumulatorType, instrumentName }: CalendarHeadersProps) {
  const columnWithPercentage = 100 / columns.length;

  return (
    <tr
    >
      {columns.map((column: string) => {
        const modifiedColumn = ['FxoSquareTargetAccumulator', 'FxoPauseTargetAccumulator'].includes(instrumentName) && column === 'strike' ? 'referencestrike' : column;

        const shouldImprovedStrikeTooltipAppear = ['FxoSquareTargetAccumulator'].includes(instrumentName) && column === 'improvedStrike';

        return (
          <th className={`text-end${column === 'cumulatedAmount' ? ' borders pe-3' : ''}`} style={{ width: `${columnWithPercentage.toFixed(2)}%` }} key={column}>
            <CalendarTooltip labelId="improvedStrikeTooltip" shouldShowTooltip={shouldImprovedStrikeTooltipAppear}>
              <FormattedMessage id={`app.calendar.table.${modifiedColumn}`} values={{ accumulatorType, instrumentName }} />
            </CalendarTooltip>
          </th>
        );
      })}
    </tr>
  );
}
