import type { AppAction } from '@/store/app.actions';
import type { AppState } from '@/store/app.state';
import type { AppDispatch } from '@/store/store';
import type { TrackAction, TrackRfq, UpdateSelectedCompanyName } from '@/utils/useMatomo';
import type { Middleware } from '@reduxjs/toolkit';

export const matomoMiddleware =
  (trackAction: TrackAction, trackRfq: TrackRfq, updateSelectedCompanyName: UpdateSelectedCompanyName): Middleware<{}, AppState, AppDispatch> =>
  (api) =>
  (next) =>
  (currentAction) => {
    const state = api.getState();
    const action = currentAction as AppAction;

    switch (action.type) {
      case 'USER_LOADED':
        updateSelectedCompanyName(action.user.companies[0].companyName);
        break;

      case 'CHANGE_COMPANY':
        updateSelectedCompanyName(action.company.companyName);
        break;

      case 'LOCATION_CHANGE':
        trackAction('Opened page');
        break;

      case 'STREAM_REQUESTED':
        trackRfq('RFQs', 'Started RFQ');
        break;

      case 'EXECUTE':
        trackAction('Triggered execution');
        break;

      case 'EXECUTED':
        if (state.rfs.status !== 'idle' && state.rfs.status !== 'starting-request' && state.rfs.status !== 'cancelling') {
          if (state.rfs.product === 'FxPredeliver') {
            trackRfq('Execution success', 'Executed predeliver on forward');
          } else if (state.rfs.product === 'FxSpot') {
            trackRfq('Execution success', 'Executed spot');
          } else if (state.rfs.product === 'FxFwd') {
            trackRfq('Execution success', 'Executed forward');
          }
        }
        break;

      case 'QUOTING_TIMEOUT':
        trackRfq('Execution errors', 'Reached RFQ timeout');
        if (state.rfs.status !== 'idle' && state.rfs.status !== 'starting-request' && state.rfs.status !== 'cancelling') {
          if (state.rfs.product === 'FxPredeliver') {
            trackRfq('Execution abandoned', 'Abandoned predeliver on forward');
          } else if (state.rfs.product === 'FxSpot') {
            trackRfq('Execution abandoned', 'Abandoned spot');
          } else if (state.rfs.product === 'FxFwd') {
            trackRfq('Execution abandoned', 'Abandoned forward');
          }
        }
        break;

      case 'ADD_ERROR':
        if (state.rfs.status === 'quoting' || state.rfs.status === 'requesting') {
          const { error } = action;
          if (error.code.includes('OVERSHOT')) {
            trackRfq('Execution errors', 'Reached fat finger limit');
          } else if (error.code.includes('CREDIT_CHECK')) {
            if (error.creditCheckType === 'NOLIMIT') {
              trackRfq('Execution errors', 'Has been refused to trade bc no CVAR limit defined');
            }
            if (error.creditCheckType === 'CVAR') {
              trackRfq('Execution errors', 'Reached CVAR limit');
            }
            if (error.creditCheckType === 'RDL') {
              trackRfq('Execution errors', 'Reached RDL limit');
            }
          } else if (error.code.includes('CUTOFF')) {
            trackRfq('Execution errors', 'Reached cut-off');
          } else {
            trackRfq('Execution errors', 'Faced an unknown error when triggered execution');
          }
        }
        break;

      case 'FORM_CHANGE':
        if (action.formChange.buyCurrency && action.formChange.sellCurrency) {
          trackAction('Switched currencies');
        } else if (action.formChange.buyCurrency && action.formChange.sellCurrency === undefined) {
          trackAction('Changed buy currency');
        } else if (action.formChange.buyCurrency === undefined && action.formChange.sellCurrency) {
          trackAction('Changed sell currency');
        } else if (action.formChange.buyAmount) {
          trackAction('Changed buy amount');
        } else if (action.formChange.sellAmount) {
          trackAction('Changed sell amount');
        } else if (action.formChange.date) {
          trackAction('Changed value date');
        }

        break;

      default:
        break;
    }
    return next(action);
  };
