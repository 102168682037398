import { Spinner } from '@/share/Spinner/Spinner';
import { useLazySendEmailQuery } from '@/store/boost-mail/boostMail.api';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import './SuccessBoostModal.scss';
import './ErrorBoostModal.scss';
import type { AppState } from '@/store/app.state';
import { selectBlockExecution } from '@/store/state/ui/ui.selectors';
import { selectCurrentUser, selectNavigateAsUser, selectSelectedCompany } from '@/store/state/user/user.selectors';
import { useSelector } from 'react-redux';
import { ErrorModal } from './ErrorEmailModal';
import { SuccessModal } from './SuccessEmailModal';

export const BoostEmailButton = () => {
  const selectedCompany = useSelector((state: AppState) => selectSelectedCompany(state));
  const company = selectedCompany ?? { companyBdrId: '', companyName: '' };
  const { companyBdrId = '', companyName = '' } = company;

  const [trigger, { isFetching, isSuccess, isError, status }] = useLazySendEmailQuery();
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState<boolean>(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<boolean>(false);

  const usrNavigateAsUser = useSelector(selectNavigateAsUser);
  const currentUser = useSelector(selectCurrentUser);
  const blockExecution = useSelector(selectBlockExecution);

  const canBoostEmail = usrNavigateAsUser === undefined && currentUser?.canExecute === true && !blockExecution;

  const toggle = () => {
    setIsSuccessModalOpen(!isSuccessModalOpen);
  };
  const toggleErrorModal = () => {
    setIsErrorModalOpen(!isErrorModalOpen);
  };

  useEffect(() => {
    if (isSuccess && status === 'fulfilled') {
      setIsSuccessModalOpen(true);
    }

    if (isError) {
      setIsErrorModalOpen(true);
    }
  }, [isSuccess, isError, status]);

  return (
    <>
      <button
        type="button"
        className="btn btn-default"
        disabled={isFetching || !canBoostEmail}
        onClick={() => {
          trigger({ companyBdrId, companyName });
        }}
        data-e2e="boost-button"
      >
        <FormattedMessage id="home.boost.button.label" />

        {isFetching && <Spinner />}
      </button>

      <SuccessModal open={isSuccessModalOpen} toggle={toggle} />
      <ErrorModal open={isErrorModalOpen} toggle={toggleErrorModal} />
    </>
  );
};
