import type React from "react";
import { FormattedMessage } from "react-intl";
import { isWithinInterval, parseISO, startOfDay } from "date-fns";
import { UncontrolledTooltip } from "reactstrap";
import { isDefined } from "@sgme/fp";
import type {
  FormattedTrade,
  ForwardTrade,
  MyHedgeTrade,
  TradeFamily,
} from "@/models/trade";
import { FeatureToggle } from "@/share/FeatureToggle/FeatureToggle";
import { isSameOrBefore } from "@/utils/dates";
import { useFormattingContext } from "@/utils/format";
import { TradeButton } from "./TradeButton";
import { ToggleButton } from "./ToggleButton";
import { capitalizeFirstLetter } from "@/utils/strings";
import { selectFeatureToggleActive } from "@/store/state/ui/ui.selectors";
import { getToggleNameFromInstrument } from "@/App/utils/featureToggle";
import { isAccumulator } from "@/utils/predicates";
import { useAppSelector } from "@/store/store";
import { selectHasSwapRight } from "@/store/state/user/user.selectors";

// ████████╗██████╗  █████╗ ██████╗ ███████╗    ██████╗  ██████╗ ██╗    ██╗
// ╚══██╔══╝██╔══██╗██╔══██╗██╔══██╗██╔════╝    ██╔══██╗██╔═══██╗██║    ██║
//    ██║   ██████╔╝███████║██║  ██║█████╗      ██████╔╝██║   ██║██║ █╗ ██║
//    ██║   ██╔══██╗██╔══██║██║  ██║██╔══╝      ██╔══██╗██║   ██║██║███╗██║
//    ██║   ██║  ██║██║  ██║██████╔╝███████╗    ██║  ██║╚██████╔╝╚███╔███╔╝
//    ╚═╝   ╚═╝  ╚═╝╚═╝  ╚═╝╚═════╝ ╚══════╝    ╚═╝  ╚═╝ ╚═════╝  ╚══╝╚══╝

export interface TradeRowProps {
  family: TradeFamily;
  trade: MyHedgeTrade;
  spotDate?: string;
  onPredeliver: (trade: ForwardTrade) => void;
  onRollover: (trade: ForwardTrade) => void;
  onOpenCalendar: (reference: string) => void;
  subRow?: boolean;
  toggle?: () => void;
  isOpen?: boolean;
  isLastRow?: boolean;
  twoDaysBeforePaymentDate?: string;
}

export function TradeRow({
  family,
  trade,
  onPredeliver,
  onRollover,
  spotDate,
  subRow = false,
  toggle,
  onOpenCalendar,
  isOpen = false,
  isLastRow,
}: TradeRowProps) {
  const { mapTrade, formatAmountWithPrecision } = useFormattingContext();
  const mappedTrade = mapTrade(trade);
  const isPauseTarget = trade.instrumentName === "FxoPauseTargetAccumulator";

  const firstStrikeUp =
    isAccumulator(trade!, family!) && trade.xOneCalendar[0]?.strike1;
  const firstStrikeDown =
    isAccumulator(trade!, family!) && trade.xOneCalendar[0]?.strike2;
  const isSameUpDownsStrikes =
    isAccumulator(trade!, family!) &&
    trade.xOneCalendar.every(
      (row) =>
        `${row.strike2} / ${row.strike1}` ===
        `${firstStrikeDown} / ${firstStrikeUp}`
    );
  const hasDifferentStrikes = !isSameUpDownsStrikes;

  return (
    <div className="card-grid-row fw-medium" data-e2e={mappedTrade.reference}>
      <TradeRowCell subRow={subRow} first isLastRow={isLastRow}>
        <div className={`d-flex align-items-start ${subRow ? "ms-3" : ""} `}>
          {toggle && (
            <ToggleButton isOpen={isOpen} onClick={toggle} className="me-3" />
          )}
          <div>
            <TradeCoverColumn mappedTrade={mappedTrade} subRow={subRow} />
          </div>
        </div>
      </TradeRowCell>

      <TradeRowCell className="text-end" subRow={subRow} isLastRow={isLastRow}>
        <TradeAmountColumn mappedTrade={mappedTrade} />
      </TradeRowCell>

      <TradeRowCell subRow={subRow} isLastRow={isLastRow}>
        <p className="mb-0">{mappedTrade.way}</p>
      </TradeRowCell>

      <TradeRowCell className="text-end" subRow={subRow} isLastRow={isLastRow}>
        {!isPauseTarget ? (
          <TradeRateColumn
            mappedTrade={mappedTrade}
            trade={trade}
            family={family}
          />
        ) : (
          <TradeStrikeUpAndDownColumn
            hasDifferentStrikes={hasDifferentStrikes}
            strikeUp={formatAmountWithPrecision(trade.strikeUp!, 5)}
            strikeDown={formatAmountWithPrecision(trade.strikeDown!, 5)}
          />
        )}
      </TradeRowCell>

      <TradeRowCell subRow={subRow} isLastRow={isLastRow}>
        <p className={`mb-0 ${mappedTrade.isToday ? "text-info" : ""}`}>
          {capitalizeFirstLetter(mappedTrade.date)}
        </p>
      </TradeRowCell>

      {/* TODO 4904 : adapt css based on subrow and not on the family */}
      <TradeRowCell
        className={family === "OptionStrategies" ? "text-end" : ""}
        subRow={subRow}
        last
        isLastRow={isLastRow}
      >
        <TradeActionColumn
          twoDaysBeforePaymentDate={mappedTrade.twoDaysBeforePaymentDate}
          family={family}
          trade={trade}
          spotDate={spotDate}
          onRollover={onRollover}
          onPredeliver={onPredeliver}
          onOpenCalendar={onOpenCalendar}
        />
      </TradeRowCell>
    </div>
  );
}

//  █████╗  ██████╗████████╗██╗ ██████╗ ███╗   ██╗     ██████╗ ██████╗ ██╗     ██╗   ██╗███╗   ███╗███╗   ██╗
// ██╔══██╗██╔════╝╚══██╔══╝██║██╔═══██╗████╗  ██║    ██╔════╝██╔═══██╗██║     ██║   ██║████╗ ████║████╗  ██║
// ███████║██║        ██║   ██║██║   ██║██╔██╗ ██║    ██║     ██║   ██║██║     ██║   ██║██╔████╔██║██╔██╗ ██║
// ██╔══██║██║        ██║   ██║██║   ██║██║╚██╗██║    ██║     ██║   ██║██║     ██║   ██║██║╚██╔╝██║██║╚██╗██║
// ██║  ██║╚██████╗   ██║   ██║╚██████╔╝██║ ╚████║    ╚██████╗╚██████╔╝███████╗╚██████╔╝██║ ╚═╝ ██║██║ ╚████║
// ╚═╝  ╚═╝ ╚═════╝   ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝     ╚═════╝ ╚═════╝ ╚══════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝  ╚═══╝

interface TradeColumnProps {
  mappedTrade: FormattedTrade;
  subRow?: boolean;
  trade?: MyHedgeTrade;
  family?: TradeFamily;
}

export function TradeActionColumn({
  trade,
  spotDate,
  onRollover,
  onPredeliver,
  onOpenCalendar,
  twoDaysBeforePaymentDate,
}: Omit<TradeRowProps, "subRow" | "actionSlot">) {
  const canSeeActionButtons = useAppSelector(selectHasSwapRight);
  const isPredeliverExpiring =
    isDefined(spotDate) &&
    isSameOrBefore(
      startOfDay(parseISO(trade.maturitydate)),
      startOfDay(parseISO(spotDate))
    );
  const isRolloverReady =
    isDefined(spotDate) &&
    isWithinInterval(startOfDay(parseISO(trade.maturitydate)), {
      start: startOfDay(new Date()),
      end: startOfDay(parseISO(spotDate)),
    });
  const isEmpty =
    trade.remainingAmount === null ||
    trade.remainingAmount === undefined ||
    trade.remainingAmount === 0;

  const toggleName = getToggleNameFromInstrument(trade.instrumentName);

  const isFeatureActive = useAppSelector((state) =>
    selectFeatureToggleActive(state, toggleName)
  );

  const showPredeliverButtons = trade.instrumentName === "FxOutright";
  const showRolloverButtons = trade.instrumentName === "FxOutright";

  const showCalendarButton = (instrumentName: string) =>
    [
      "FxoForwardAccumulator",
      "FxoTargetAccumulator",
      "FxoSquareTargetAccumulator",
      "FxoPauseTargetAccumulator",
      "FxoVanillaAccumulator",
    ].includes(instrumentName); // isAccumulator(family) this functionnality should activated back when dev is done

  const disableReason = !canSeeActionButtons
    ? "noRight"
    : isEmpty
    ? "empty"
    : undefined;

  return (
    <div className="d-flex">
      {showPredeliverButtons && (
        <FeatureToggle feature="predeliver">
          {(active) =>
            active && !isPredeliverExpiring ? (
              <TradeButton
                type="predeliver"
                reference={trade.reference}
                disabledReason={disableReason}
                onClick={() => onPredeliver(trade)}
                className="me-2"
              />
            ) : (
              <TradeButton
                type="predeliver"
                reference={trade.reference}
                disabledReason={reasonWhyPredeliverDisabled(
                  active,
                  isEmpty,
                  canSeeActionButtons
                )}
                className="me-2"
              />
            )
          }
        </FeatureToggle>
      )}

      {showRolloverButtons && (
        <FeatureToggle feature="rollover">
          {(active) =>
            active && isRolloverReady ? (
              <TradeButton
                type="rollover"
                twoDaysBeforePaymentDate={twoDaysBeforePaymentDate}
                reference={trade.reference}
                disabledReason={disableReason}
                onClick={() => onRollover(trade)}
              />
            ) : (
              <TradeButton
                type="rollover"
                twoDaysBeforePaymentDate={twoDaysBeforePaymentDate}
                reference={trade.reference}
                disabledReason={reasonWhyRolloverDisabled(
                  active,
                  isEmpty,
                  canSeeActionButtons
                )}
              />
            )
          }
        </FeatureToggle>
      )}

      {showCalendarButton(trade.instrumentName) && (
        <TradeButton
          type="calendar"
          reference={trade.reference}
          disabledReason={!isFeatureActive ? "availableSoon" : undefined}
          onClick={() => onOpenCalendar(trade.reference)}
        />
      )}
    </div>
  );
}

function TradeBadges({ badges }: { badges: string[] }) {
  return (
    <>
      {badges.map((badge) =>
        !badge.includes("KO") ? (
          <span
            key={badge}
            className="badge badge-discreet-primary badge-prepend-square badge-sm me-1"
          >
            {badge}
          </span>
        ) : (
          ""
        )
      )}
    </>
  );
}

const reasonWhyPredeliverDisabled = (
  active: boolean,
  isEmpty: boolean,
  canSeeActionButtons: boolean
) => {
  if (!canSeeActionButtons) {
    return "noRight";
  }

  if (!active) {
    return "availableSoon";
  }

  if (isEmpty) {
    return "empty";
  }

  return "expiring";
};

const reasonWhyRolloverDisabled = (
  active: boolean,
  isEmpty: boolean,
  canSeeActionButtons: boolean
) => {
  if (!canSeeActionButtons) {
    return "noRight";
  }

  if (!active) {
    return "availableSoon";
  }

  if (isEmpty) {
    return "empty";
  }

  return "expiring";
};

//  ██████╗ ██████╗ ██╗     ██╗   ██╗███╗   ███╗███╗   ██╗███████╗
// ██╔════╝██╔═══██╗██║     ██║   ██║████╗ ████║████╗  ██║██╔════╝
// ██║     ██║   ██║██║     ██║   ██║██╔████╔██║██╔██╗ ██║███████╗
// ██║     ██║   ██║██║     ██║   ██║██║╚██╔╝██║██║╚██╗██║╚════██║
// ╚██████╗╚██████╔╝███████╗╚██████╔╝██║ ╚═╝ ██║██║ ╚████║███████║
//  ╚═════╝ ╚═════╝ ╚══════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝  ╚═══╝╚══════╝

function TradeCoverColumn({
  mappedTrade: { productName, badges, currencyPair, reference, subCount },
  subRow,
}: TradeColumnProps) {
  return (
    <>
      <p className={`mb-1 ${subRow ? "" : "fw-bold"}`}>
        {productName}
        {subCount && (
          <span className="badge badge-discreet-secondary rounded-pill ms-2">
            {subCount}
          </span>
        )}
      </p>

      {badges.length > 0 && (
        <p className="mb-1">
          <TradeBadges badges={badges} />
        </p>
      )}
      <p className="mb-0">
        {currencyPair}
        <span className="text-secondary ms-2">{reference}</span>
      </p>
    </>
  );
}

function TradeAmountColumn({ mappedTrade }: TradeColumnProps) {
  return (
    <>
      <p className="mb-0">{mappedTrade.amount}</p>
      {mappedTrade.secondAmount !== undefined && (
        <p className="text-secondary mt-1 mb-0">{mappedTrade.secondAmount}</p>
      )}
    </>
  );
}

function TradeStrikeUpAndDownColumn({
  strikeUp,
  strikeDown,
  hasDifferentStrikes,
}: {
  strikeUp: string;
  strikeDown: string;
  hasDifferentStrikes: boolean;
}) {
  return (
    <>
      {hasDifferentStrikes && (
        <p className="text-secondary mb-0">
          <FormattedMessage id="app.calendar.strikes.multiples.tradeRow.title" />
        </p>
      )}
      <p className="text-primary mb-0">
        {/* when we are in pause Target we automatically have strikeUp and StrikeDown */}
        <FormattedMessage
          id="trades.tradeRow.strikesUp"
          values={{
            strikeUp,
          }}
        />
      </p>
      <p className="text-primary mt-1">
        <FormattedMessage
          id="trades.tradeRow.strikesDown"
          values={{
            strikeDown,
          }}
        />
      </p>
    </>
  );
}

function TradeRateColumn({
  mappedTrade: { reference, strike, showInfo, fxSpot, farPoints },
  trade,
  family,
}: TradeColumnProps) {
  // we need to check if we have the same strike in every row
  const firstStrike =
    isAccumulator(trade!, family!) && trade.xOneCalendar?.[0]?.strike;
  const hasDifferentStrikes =
    isAccumulator(trade!, family!) &&
    !trade.xOneCalendar?.every((row) => row?.strike === firstStrike);

  return (
    <div className="mb-0">
      {showInfo && (
        <>
          <i
            id={`${reference}_infoIcon`}
            className="icon icon-xs text-secondary pe-1"
          >
            info_outline
          </i>
          <UncontrolledTooltip
            data-toggle="tooltip"
            target={`${reference}_infoIcon`}
            placement="top"
          >
            <p className="mb-0 text-nowrap">
              <FormattedMessage
                id="trades.tradeRow.tooltip.fxSpot"
                values={{ fxSpot }}
              />
            </p>
            <p className="mb-0 text-nowrap">
              <FormattedMessage
                id="trades.tradeRow.tooltip.farPoints"
                values={{
                  farPoints: !farPoints?.includes("-")
                    ? `+${farPoints}`
                    : farPoints,
                }}
              />
            </p>
          </UncontrolledTooltip>
        </>
      )}
      {hasDifferentStrikes && (
        <p className="text-secondary mb-2">
          <FormattedMessage id="trades.tradeRow.strike.multiple" />
        </p>
      )}
      {strike}
    </div>
  );
}

//  ██████╗███████╗██╗     ██╗
// ██╔════╝██╔════╝██║     ██║
// ██║     █████╗  ██║     ██║
// ██║     ██╔══╝  ██║     ██║
// ╚██████╗███████╗███████╗███████╗
//  ╚═════╝╚══════╝╚══════╝╚══════╝

type TradeRowCellProps = {
  first?: boolean;
  last?: boolean;
  subRow?: boolean;
  children: React.ReactNode;
  className?: string;
  isLastRow?: boolean;
};

export function TradeRowCell({
  first = false,
  last = false,
  subRow = false,
  children,
  className,
  isLastRow,
}: TradeRowCellProps) {
  const borderClassName = !subRow ? getBorderClass(first, last, isLastRow) : "";
  const mpClassName = `${!subRow ? "py-3" : "pb-3"} ${last ? "px-4" : "ps-4"}`;
  const classNames = [borderClassName, mpClassName, className].join(" ");

  return <div className={classNames}>{children}</div>;
}

export function TradeRowContainer({
  className,
  children,
}: {
  className?: string;
  children: React.ReactNode;
}) {
  return (
    <div
      className={`card-grid ${isDefined(className) ? className : ""}`}
      data-e2e="trade-row"
    >
      {children}
    </div>
  );
}

// ███████╗████████╗██╗   ██╗██╗     ███████╗███████╗
// ██╔════╝╚══██╔══╝╚██╗ ██╔╝██║     ██╔════╝██╔════╝
// ███████╗   ██║    ╚████╔╝ ██║     █████╗  ███████╗
// ╚════██║   ██║     ╚██╔╝  ██║     ██╔══╝  ╚════██║
// ███████║   ██║      ██║   ███████╗███████╗███████║
// ╚══════╝   ╚═╝      ╚═╝   ╚══════╝╚══════╝╚══════╝

const getBorderClass = (
  firstCell: boolean,
  lastCell: boolean,
  isLastRow?: boolean
) => {
  const borderClasses = ["border"];

  if (!lastCell) {
    borderClasses.push("border-end-0");
  }

  if (!firstCell) {
    borderClasses.push("border-start-0");
  }

  if (!isLastRow) {
    borderClasses.push("border-bottom-0");
  }

  return borderClasses.join(" ");
};
