import type { AppState } from '@/store/app.state';
import { useSgwtWidgets } from '@sgwt/sgwt-widgets-react';
import { connect } from 'react-redux';

const e2eUsers = ['sales.myfx@sgcib.com', 'dow.jane.e2e@sgcib.com', 'spot.jane@sgcib.com', 'readonly.myfx@sgcib.com'];

function DebugHeaderRaw({ streaming }: { streaming: string }) {
  const { sgwtConnect } = useSgwtWidgets();

  const claims = sgwtConnect?.sgwtConnect?.getIdTokenClaims();

  return window.sgmeConfiguration.env.toLowerCase().includes('dev') && claims && e2eUsers.includes(claims.sub) ? (
    <div className="sgwt-account-center">
      <div className="sgwt-account-center-tags">
        <div className="" data-e2e="e2e-debug-header">
          {streaming}
        </div>
      </div>
    </div>
  ) : null;
}

export const DebugHeader = connect<{ streaming: string }, Record<string, unknown>, Record<string, unknown>, AppState>((state) => ({
  streaming: JSON.stringify(state.streaming),
}))(DebugHeaderRaw);
