/* eslint-disable no-param-reassign */
import { isDefined, isNotDefined } from '@sgme/fp';
import { DecoratedFields, FxoPauseTargetAccumulatorBaseCalendarFields, FxoPauseTargetAccumulatorCalendarFields, possibleIsCheckedFieldsName } from '@/models/calendar';
import { TargetAccumulatorTrade, XOneCalendarEntry } from '@/models/trade';
import { computeWay } from '@/App/utils/computeWay';
import { formatAmountWithPrecision } from '@/utils/format';

export const fxoPauseTargetAccumulatorFieldsConfig: ReadonlyArray<keyof XOneCalendarEntry> = [
  'fixingDate',
  'payDate',
  'amount2',
  'amount1',
  'step1',
  'step2',
  'steps',
  'strike1',
  'strike2',
  'fixing',
  'leverage',
  'power',
] as const;

const isAmount = (key: keyof FxoPauseTargetAccumulatorCalendarFields): key is 'amount1' | 'amount2' => ['amount1', 'amount2'].includes(key);

//
//
//
//  ██████╗ ██████╗ ███╗   ███╗██████╗ ██╗   ██╗████████╗███████╗    ███████╗██╗███████╗██╗     ██████╗ ███████╗
// ██╔════╝██╔═══██╗████╗ ████║██╔══██╗██║   ██║╚══██╔══╝██╔════╝    ██╔════╝██║██╔════╝██║     ██╔══██╗██╔════╝
// ██║     ██║   ██║██╔████╔██║██████╔╝██║   ██║   ██║   █████╗      █████╗  ██║█████╗  ██║     ██║  ██║███████╗
// ██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██║   ██║   ██║   ██╔══╝      ██╔══╝  ██║██╔══╝  ██║     ██║  ██║╚════██║
// ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ╚██████╔╝   ██║   ███████╗    ██║     ██║███████╗███████╗██████╔╝███████║
//  ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝      ╚═════╝    ╚═╝   ╚══════╝    ╚═╝     ╚═╝╚══════╝╚══════╝╚═════╝ ╚══════╝

const computeIsChecked = (currentField: Exclude<possibleIsCheckedFieldsName, 'amount3'>, fixing: number, step1: number, step2: number) => {
  if (currentField === 'amount1') {
    return fixing > step2;
  }

  if (currentField === 'amount2') {
    return fixing < step1;
  }

  if (step1 <= fixing && fixing <= step2) {
    return false;
  }

  throw new Error('shouldn`t be called for other fields than amount 1 and 2');
};

const computeImprovedStrikeField = (rowFields: FxoPauseTargetAccumulatorBaseCalendarFields): { value: number | undefined } => {
  const { fixing, strike1, strike2, step1, step2 } = rowFields;

  if (isNotDefined(fixing)) {
    return { value: undefined };
  }
  if (fixing < step1!) {
    return { value: strike1 };
  }
  if (fixing > step2!) {
    return { value: strike2 };
  }
  return { value: undefined };
};

const computeTargetField = (
  { fixing, strike1, strike2, step1, step2 }: DecoratedFields<FxoPauseTargetAccumulatorCalendarFields>,
  trade: TargetAccumulatorTrade,
): number | undefined => {
  if (isNotDefined(fixing.value)) {
    return undefined;
  }

  if (computeWay(trade) === 'buy' && fixing.value < step1.value!) {
    return Math.max((strike1.value as number) - (fixing.value as number), 0);
  }
  if (computeWay(trade) === 'sell' && fixing.value > step2.value!) {
    return Math.max((fixing.value as number) - (strike2.value as number), 0);
  }
  return 0;
};

const computeCumulatedTargetField = (allRows: DecoratedFields<FxoPauseTargetAccumulatorCalendarFields>[], index: number): number =>
  allRows.reduce((acc, { target }, innerKey) => {
    if (innerKey <= index && isDefined(target?.value)) {
      return acc + (target.value as number);
    }
    return acc;
  }, 0);

const computeCumulatedAmount = (allRows: DecoratedFields<FxoPauseTargetAccumulatorCalendarFields>[], index: number): number =>
  allRows.reduce((acc, { amount1, amount2 }, innerKey) => {
    if (innerKey <= index) {
      if (amount1?.isChecked) {
        acc += amount1.value as number;
      }

      if (amount2?.isChecked) {
        acc += amount2.value as number;
      }
    }
    return acc;
  }, 0);

const computeRemainingTargetField = (cumulatedTarget: number | undefined, tradeTarget: number) => {
  const remainingTargetValue = isDefined(cumulatedTarget) ? tradeTarget - cumulatedTarget : undefined;
  return {
    value: isDefined(remainingTargetValue) && remainingTargetValue > 0 ? remainingTargetValue : undefined,
  };
};

//
//
//
// ██████╗ ███████╗ ██████╗ ██████╗ ██████╗  █████╗ ████████╗███████╗    ███████╗██╗███████╗██╗     ██████╗ ███████╗
// ██╔══██╗██╔════╝██╔════╝██╔═══██╗██╔══██╗██╔══██╗╚══██╔══╝██╔════╝    ██╔════╝██║██╔════╝██║     ██╔══██╗██╔════╝
// ██║  ██║█████╗  ██║     ██║   ██║██████╔╝███████║   ██║   █████╗      █████╗  ██║█████╗  ██║     ██║  ██║███████╗
// ██║  ██║██╔══╝  ██║     ██║   ██║██╔══██╗██╔══██║   ██║   ██╔══╝      ██╔══╝  ██║██╔══╝  ██║     ██║  ██║╚════██║
// ██████╔╝███████╗╚██████╗╚██████╔╝██║  ██║██║  ██║   ██║   ███████╗    ██║     ██║███████╗███████╗██████╔╝███████║
// ╚═════╝ ╚══════╝ ╚═════╝ ╚═════╝ ╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚══════╝    ╚═╝     ╚═╝╚══════╝╚══════╝╚═════╝ ╚══════╝

const decorateAmountField = (
  rowFields: DecoratedFields<FxoPauseTargetAccumulatorCalendarFields>,
  currentField: Exclude<possibleIsCheckedFieldsName, 'amount3'>,
  tradeTarget: number,
  badges: string[] | undefined,
  cumulatedTarget?: number,
): {
  isChecked: boolean;
  value: number | string | undefined;
  isAdjStrike: boolean;
  overriddenPrecision?: number;
} => {
  const { fixing, step1, step2, target: rowTarget } = rowFields;
  const defaultAmountField = {
    ...rowFields[currentField],
    isChecked: false,
    isAdjStrike: false,
    overriddenPrecision: 2,
  };
  if (isNotDefined(tradeTarget) || isNotDefined(fixing?.value) || isNotDefined(cumulatedTarget)) {
    return defaultAmountField;
  }

  const computeAdjustedAmount =
    computeIsChecked(currentField, fixing.value as number, step1.value as number, step2.value as number) ?
      ((defaultAmountField.value as number) * (tradeTarget - cumulatedTarget)) / cumulatedTarget
    : (defaultAmountField.value as number);

  if (cumulatedTarget < tradeTarget) {
    if (currentField === 'amount1' || currentField === 'amount2') {
      return {
        ...defaultAmountField,
        isChecked: computeIsChecked(currentField, fixing.value as number, step1.value as number, step2.value as number),
      };
    }
  } else {
    const koConvention = badges?.find((badgeName) => badgeName.includes('KO')); // the information is gotten from regex of the description
    const isCurrentRowKnockOut = cumulatedTarget - (rowTarget.value as number) < tradeTarget;

    if (isCurrentRowKnockOut) {
      if (koConvention?.includes('Full')) {
        return {
          ...defaultAmountField,
          isChecked: computeIsChecked(currentField, fixing.value as number, step1.value as number, step2.value as number),
        };
      }

      if (koConvention?.includes('Adj Amount')) {
        return {
          ...defaultAmountField,
          isChecked: computeIsChecked(currentField, fixing.value as number, step1.value as number, step2.value as number),
          value: computeAdjustedAmount,
        };
      }

      if (koConvention?.includes('Adj Strike')) {
        return {
          ...defaultAmountField,
          isChecked: computeIsChecked(currentField, fixing.value as number, step1.value as number, step2.value as number),
          isAdjStrike: true,
        };
      }
    }
    // when koconvention is NONE should have zero impact on isChecked logic
    return defaultAmountField;
  }
  return defaultAmountField;
};

const decorateFixingField = (
  cumulatedTarget: number | undefined,
  tradeTarget: number,
  fixing: DecoratedFields<FxoPauseTargetAccumulatorCalendarFields>['fixing'],
  step1?: string | number,
  step2?: string | number,
  badges?: string[],
) => {
  const koConvention = badges?.find((badgeName) => badgeName.includes('KO')); // the information is gotten from regex of the description
  const isFixingEditable = !koConvention?.includes('Full') && !koConvention?.includes('Adj Strike') && !koConvention?.includes('Adj Amount');

  return {
    ...fixing,
    ...{
      isFixingEditable,
      isKo: isDefined(cumulatedTarget) && cumulatedTarget >= tradeTarget,
      isUncertain: fixing.value === step1 || fixing.value === step2,
    },
  };
};

//
//
//
//  █████╗ ██████╗ ██████╗     ███████╗██╗███████╗██╗     ██████╗ ███████╗
// ██╔══██╗██╔══██╗██╔══██╗    ██╔════╝██║██╔════╝██║     ██╔══██╗██╔════╝
// ███████║██║  ██║██║  ██║    █████╗  ██║█████╗  ██║     ██║  ██║███████╗
// ██╔══██║██║  ██║██║  ██║    ██╔══╝  ██║██╔══╝  ██║     ██║  ██║╚════██║
// ██║  ██║██████╔╝██████╔╝    ██║     ██║███████╗███████╗██████╔╝███████║
// ╚═╝  ╚═╝╚═════╝ ╚═════╝     ╚═╝     ╚═╝╚══════╝╚══════╝╚═════╝ ╚══════╝

const addImprovedStrikeAfterFixingField = (rowFields: FxoPauseTargetAccumulatorCalendarFields) =>
  Object.entries(rowFields).reduce((result, [key, value]) => {
    result[key as keyof FxoPauseTargetAccumulatorBaseCalendarFields] = { value };
    if (key === 'fixing') {
      const improvedStrike = computeImprovedStrikeField(rowFields);
      result.improvedStrike = {
        value: improvedStrike.value,
      };
    }
    return result;
  }, {} as DecoratedFields<FxoPauseTargetAccumulatorCalendarFields>);

const addTargetAfterImprovedStrikeField = (rowFields: DecoratedFields<FxoPauseTargetAccumulatorCalendarFields>, trade: TargetAccumulatorTrade) =>
  Object.entries(rowFields).reduce((result, [key, value]) => {
    result[key as keyof FxoPauseTargetAccumulatorCalendarFields] = value;
    if (key === 'improvedStrike') {
      result.target = { value: computeTargetField(rowFields, trade) };
    }
    return result;
  }, {} as DecoratedFields<FxoPauseTargetAccumulatorCalendarFields>);

const addCumulatedAmountAndModifyImprovedStrike = (
  rowFields: DecoratedFields<FxoPauseTargetAccumulatorCalendarFields>,
  rowIndex: number,
  trade: TargetAccumulatorTrade,
  allRowWithCumulatedTarget: DecoratedFields<FxoPauseTargetAccumulatorCalendarFields>[],
  locale?: string,
) =>
  Object.entries(rowFields).reduce((acc, [key, value]) => {
    if (key === 'leverage' || key === 'power' || key === 'strike1' || key === 'strike2' || key === 'step1' || key === 'step2') {
      // we do not display those fields
      return acc;
    }

    const currentKey = key as keyof FxoPauseTargetAccumulatorCalendarFields;
    acc[currentKey] = value;

    if (currentKey === 'improvedStrike' && rowFields.cumulatedTarget.value && (rowFields.amount1.isAdjStrike || rowFields.amount2.isAdjStrike)) {
      const remainingTarget = trade.target - (rowFields.cumulatedTarget.value as number);

      acc[currentKey].value = computeWay(trade) === 'buy' ? (rowFields.fixing.value as number) + remainingTarget : (rowFields.fixing.value as number) - remainingTarget;
    }

    // because we want to add the cumulated amount after amount1
    if (currentKey === 'amount1') {
      acc.cumulatedAmount = {
        value:
          (
            isDefined(rowFields.fixing.value) // we want to show the cumulated amount even if none of the amounts are checked
          ) ?
            computeCumulatedAmount(allRowWithCumulatedTarget, rowIndex)
          : undefined,
        overriddenPrecision: 2,
        isCumulatedAmount: true,
      };
    }
    if (currentKey === 'steps') {
      acc.steps = {
        value: `${formatAmountWithPrecision(locale as 'fr' | 'en')(rowFields.step1.value as number, 5)} / ${formatAmountWithPrecision(locale as 'fr' | 'en')(rowFields.step2.value as number, 5)}`,
      };
    }
    return acc;
  }, {} as DecoratedFields<FxoPauseTargetAccumulatorCalendarFields>);

//
//
//
//  ██████╗ ██████╗ ███╗   ███╗██████╗ ██╗   ██╗████████╗███████╗    ██████╗  ██████╗ ██╗    ██╗███████╗
// ██╔════╝██╔═══██╗████╗ ████║██╔══██╗██║   ██║╚══██╔══╝██╔════╝    ██╔══██╗██╔═══██╗██║    ██║██╔════╝
// ██║     ██║   ██║██╔████╔██║██████╔╝██║   ██║   ██║   █████╗      ██████╔╝██║   ██║██║ █╗ ██║███████╗
// ██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██║   ██║   ██║   ██╔══╝      ██╔══██╗██║   ██║██║███╗██║╚════██║
// ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ╚██████╔╝   ██║   ███████╗    ██║  ██║╚██████╔╝╚███╔███╔╝███████║
//  ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝      ╚═════╝    ╚═╝   ╚══════╝    ╚═╝  ╚═╝ ╚═════╝  ╚══╝╚══╝ ╚══════╝

export const computeRowsFieldsForFxoPauseTargetAccumulator = (
  baseRowsFields: FxoPauseTargetAccumulatorCalendarFields[],
  trade: TargetAccumulatorTrade,
  locale?: string,
  badges?: string[],
): Array<DecoratedFields<FxoPauseTargetAccumulatorCalendarFields>> => {
  const allRowsWithImprovedStrike = baseRowsFields.map((rowFields) => addImprovedStrikeAfterFixingField(rowFields));

  const allRowsWithTarget = allRowsWithImprovedStrike.map((rowFields) => addTargetAfterImprovedStrikeField(rowFields, trade));

  const allRowWithCumulatedTarget = allRowsWithTarget.map((rowFields, rowIndex) =>
    Object.entries(rowFields).reduce((result, [key, value]) => {
      const currentKey = key as keyof FxoPauseTargetAccumulatorCalendarFields;
      result[currentKey] = value;

      const fixing = rowFields.fixing?.value;
      const step1 = rowFields.step1?.value;
      const step2 = rowFields.step2?.value;

      const cumulatedTargetValue = fixing ? computeCumulatedTargetField(allRowsWithTarget, rowIndex) : undefined;

      const cumulatedTarget = {
        value: cumulatedTargetValue,
        isKo: isDefined(cumulatedTargetValue) ? cumulatedTargetValue >= trade.target : false,
      };

      // add remaining target after cumulated target field
      if (currentKey === 'target') {
        result.cumulatedTarget = cumulatedTarget;
        result.remainingTarget = computeRemainingTargetField(cumulatedTarget.value, trade.target);
      }

      if (isAmount(currentKey)) {
        result[currentKey] = decorateAmountField(rowFields, currentKey as 'amount1' | 'amount2', trade.target, badges, cumulatedTarget.value);
      }

      if (currentKey === 'fixing') {
        result.fixing = decorateFixingField(cumulatedTarget.value, trade.target, result.fixing, step1, step2, badges);
      }

      return result;
    }, {} as DecoratedFields<FxoPauseTargetAccumulatorCalendarFields>),
  );

  const allRowWithCumulatedAmount = allRowWithCumulatedTarget.map((rowFields, rowIndex) =>
    addCumulatedAmountAndModifyImprovedStrike(rowFields, rowIndex, trade, allRowWithCumulatedTarget, locale),
  );

  return allRowWithCumulatedAmount;
};
