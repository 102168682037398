import { logger } from '@/logging/logger';
import { extractError } from '@/utils/errors';
import type { TrackRfq } from '@/utils/useMatomo';
import { type Epic, combineEpics } from 'redux-observable';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import type { AppAction } from './app.actions';
import type { AppState } from './app.state';
import { fetchActiveOrdersEpic } from './epics/blotter-fetch-active.epic';
import { blotterRealTimeEpic } from './epics/blotter-realtime.epic';
import { blotterRegisterEpic } from './epics/blotter-register.epic';
import { cancelRedirectEpic } from './epics/cancelRedirect.epic';
import { cleanFormEpic } from './epics/clean-alert-form.epic';
import { createAlertFixings } from './epics/create-alert-fixings.epic';
import { createAlertOmsEpic } from './epics/create-alert-oms.epic';
import { datesEpic } from './epics/dates.epic';
import { deleteAlertOmsEpic } from './epics/delete-alert-oms.epic';
import { disconnectEpic } from './epics/disconnect.epic';
import { executeEpic } from './epics/execute.epic';
import { formChangeEpic } from './epics/form-change.epic';
import { monitorPendingExecutionEpic } from './epics/monitor-pending-execution.epic';
import { startNavigateAsEpic } from './epics/navigate-as.epic';
import { pageChangeEpic } from './epics/page-change.epic';
import { pingEpic } from './epics/ping.epic';
import { quotesEpic } from './epics/quotes.epic';
import { requestForStreamEpic } from './epics/request-for-stream.epic';
import { stopStreamEpic } from './epics/stop-stream.epic';
import { tradesSpotDatesEpic } from './epics/trades-spot-dates.epic';
import { tradesEpic } from './epics/trades.epic';
import { updateAlertOmsEpic } from './epics/update-alert-oms.epic';
import { validateCreateAlertFormEpic } from './epics/validate-create-alert-form-oms.epic';
import { createAppCrashedAction } from './state/appCrash/appCrash.actions';

function withAppCrashIfException(rootEpic: AppEpics): AppEpics {
  return (action$, state$) =>
    rootEpic(action$, state$, undefined).pipe(
      catchError((error) => {
        logger.logError('Epics error', { source: 'Epics', error: extractError(error) });

        return of(createAppCrashedAction('Root epics', extractError(error)));
      }),
    );
}

export type AppEpics = Epic<AppAction, AppAction, AppState>;

export const appEpic = (trackRfq: TrackRfq): AppEpics =>
  withAppCrashIfException(
    combineEpics(
      blotterRegisterEpic(),
      blotterRealTimeEpic(),
      fetchActiveOrdersEpic(),
      tradesEpic(),
      requestForStreamEpic(),
      quotesEpic(),
      executeEpic(),
      monitorPendingExecutionEpic(10000),
      formChangeEpic(),
      stopStreamEpic(),
      pageChangeEpic(trackRfq),
      datesEpic(),
      validateCreateAlertFormEpic(),
      createAlertOmsEpic(),
      createAlertFixings(),
      cleanFormEpic(),
      updateAlertOmsEpic(),
      deleteAlertOmsEpic(),
      cancelRedirectEpic(),
      startNavigateAsEpic(),
      pingEpic(),
      tradesSpotDatesEpic(),
      disconnectEpic(),
    ),
  );
