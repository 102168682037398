import type { Alert } from '@/models/alerts';
import { getDoneAlerts, getExpiredAlerts, isAlertLoading as isAlertLoadingSelector } from '@/store/state/alerts/alerts.selectors';
import { realiseAlertThunk } from '@/store/state/alerts/alerts.thunk';
import { renewAlertThunk } from '@/store/state/create-alert/create-alert.thunks';
import { useAppDispatch } from '@/store/store';
import { useMatomo } from '@/utils/useMatomo';
import * as React from 'react';
import { useCallback } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { routes } from '../routes';

function DoneMessage({ realiseAlert, alert }: { alert: Alert; realiseAlert: (a: Alert) => void }) {
  const onClick = React.useCallback(() => {
    realiseAlert(alert);
  }, [alert, realiseAlert]);

  return (
    <div className="alert alert-info d-flex mb-3" key={alert.id}>
      <span className="align-self-center">
        <FormattedMessage
          id="home.alerts.done"
          values={{
            currencyPair: alert.currencyPair,
            limitPrice: <FormattedNumber value={alert.limitPrice} minimumFractionDigits={0} maximumFractionDigits={10} />,
          }}
        />
      </span>

      <button type="button" className="btn btn-outline-primary-alt ms-auto" onClick={onClick}>
        <FormattedMessage id="home.alerts.realise" />
      </button>
    </div>
  );
}

function ExpiredMessage({ renewAlert, alert }: { alert: Alert; renewAlert: (a: Alert) => void }) {
  const { trackAction } = useMatomo();

  const onClick = useCallback(() => {
    trackAction('Renewed alert');
    renewAlert(alert);
  }, [trackAction, alert, renewAlert]);

  return (
    <div className="alert text-info bg-info-opacity-20 d-flex mb-3" key={alert.id}>
      <span className="align-self-center">
        <FormattedMessage
          id="home.alerts.expired"
          values={{
            currencyPair: alert.currencyPair,
            limitPrice: alert.limitPrice,
          }}
        />
      </span>

      <button type="button" className="btn btn-outline-info ms-auto" onClick={onClick}>
        <FormattedMessage id="home.alerts.renew" />
      </button>
    </div>
  );
}

export const Blinker = () => {
  return (
    <div className="d-flex justify-content-center align-items-center mt-2 mb-3">
      <div className="blinker blinker-md">
        <span>
          <FormattedMessage id="alerts.loading" />
        </span>
      </div>
    </div>
  );
};

export function Notifications() {
  const expireds = useSelector(getExpiredAlerts);
  const dones = useSelector(getDoneAlerts);
  const isAlertLoading = useSelector(isAlertLoadingSelector);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const renewAlert = (alert: Alert) => {
    dispatch(renewAlertThunk(alert));
    navigate(routes.CREATE_ALERT);
  };

  const realiseAlert = (alert: Alert) => {
    dispatch(realiseAlertThunk(alert));
    navigate(routes.TRADE);
  };

  if (isAlertLoading) {
    <Blinker />;
  }

  return (
    <div className="mt-3 mb-0">
      {dones.map((alert) => (
        <DoneMessage key={alert.id} alert={alert} realiseAlert={realiseAlert} />
      ))}

      {expireds.map((alert) => (
        <ExpiredMessage key={alert.id} alert={alert} renewAlert={renewAlert} />
      ))}
    </div>
  );
}
