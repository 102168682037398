import type { ISGWTConnectConfiguration } from '@sgwt/connect-core';

export interface AppConfiguration {
  env: string;
  api_url: string;
  fake_user: string;
  redirect_uri: string;
  piwik: {
    url: string;
    site_id: string;
  };
  sgconnect: ISGWTConnectConfiguration;
  signalR: {
    serverUrl: string;
    hubName: string;
    reconnectDelay: number;
  };
  sgConnectEnv: 'development' | 'homologation' | 'production'; // for widgets
}

export type Feature =
  | 'rollover'
  | 'predeliver'
  | 'alerts'
  | 'targetAccumulator'
  | 'forwardAccumulator'
  | 'squareTargetAccumulator'
  | 'pauseTargetAccumulator'
  | 'vanillaAccumulator';

export type IncomingFeatureToggle = `myFx${Capitalize<Feature>}`;

export const featureToggleMappings: Record<IncomingFeatureToggle, Feature> = {
  myFxAlerts: 'alerts',
  myFxPredeliver: 'predeliver',
  myFxRollover: 'rollover',
  myFxForwardAccumulator: 'forwardAccumulator',
  myFxTargetAccumulator: 'targetAccumulator',
  myFxSquareTargetAccumulator: 'squareTargetAccumulator',
  myFxPauseTargetAccumulator: 'pauseTargetAccumulator',
  myFxVanillaAccumulator: 'vanillaAccumulator',
};

declare global {
  interface Window {
    sgmeConfiguration: AppConfiguration;
    SGWTWidgetConfiguration: { environment: 'development' | 'homologation' | 'production' };
  }
}

const appConfig = window.sgmeConfiguration;
export const isDev = appConfig?.env ? appConfig.env === 'local' || appConfig.env === 'devci' : true;

export const isTest = appConfig?.env ? appConfig.env === 'test' : true;

export const getApiUrl = () => {
  if (window.location?.href?.includes('sgme-myfx.fr.world.socgen')) {
    return 'https://sgme-fx.fr.world.socgen/api/';
  }
  if (!window.sgmeConfiguration.api_url.endsWith('/')) {
    return `${window.sgmeConfiguration.api_url}/`;
  }
  return window.sgmeConfiguration.api_url;
};
