import type { ForwardTrade, MyHedgeTrade } from '@/models/trade';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { TradesList } from './TradesList/TradesList';
import { TradesToolbar } from './TradesToolbar';

import { startPredeliverThunk, startRolloverThunk } from '@/store/state/rfs/rfs.thunks';
import { tradesSelectedIdAction } from '@/store/state/trades/trades.actions';
import { selectSpotDates } from '@/store/state/trades/trades.selectors';
import { useAppDispatch } from '@/store/store';
import { useMatomo } from '@/utils/useMatomo';
import { useNavigate } from 'react-router';
import { MainLayout } from '../Layout/MainLayout';
import { routes } from '../routes';
import { divideTradesWithMultipleMaturityDateSubs } from '../utils/trades';
import { HeaderRow } from './Headers';
import { type TradesFilters, isTodayTrades as getTodaysTrades, processTrades } from './TradesToolbar/helpers';

interface TradesStateProps {
  trades: MyHedgeTrade[];
}

const DEFAULT_FILTERS: TradesFilters = { families: [], currencyPairs: [] };

export function TradesReceived({ trades }: TradesStateProps) {
  const { trackAction } = useMatomo();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const spotDates = useSelector(selectSpotDates);

  const [headerMaturityDateValue, setHeaderMaturityDateValue] = useState('Echéance');

  const onPredeliver = (trade: ForwardTrade) => {
    dispatch(startPredeliverThunk(trade));
    navigate(routes.USE_ENVELOPPE);
  };

  const onRollover = async (trade: ForwardTrade) => {
    await dispatch(startRolloverThunk(trade));
    navigate(routes.REPORT_ENVELOPPE);
  };

  const onOpenCalendar = (reference: string) => {
    navigate(routes.CALENDAR);
    dispatch(tradesSelectedIdAction(reference));
  };

  const [search, setSearch] = useState('');
  const [todayTradesOnly, setTodayTradesOnly] = useState(false);
  const [filters, setFilters] = useState<TradesFilters>(DEFAULT_FILTERS);

  const resetFilters = useCallback(() => setFilters(DEFAULT_FILTERS), []);

  // biome-ignore lint/correctness/useExhaustiveDependencies: reset each time trades is updated
  useEffect(() => resetFilters(), [trades]);

  const onSearchChange = useCallback(
    (value: string) => {
      trackAction('Searched an operation');

      setSearch(value);
      resetFilters();
    },
    [trackAction, resetFilters],
  );

  const onFilterChange = useCallback(
    (newFilters: TradesFilters) => {
      trackAction('Filtered operations');
      setFilters(newFilters);
    },
    [trackAction],
  );

  const onTodayOnlyChange = (isTodayOnly: boolean) => setTodayTradesOnly(isTodayOnly);

  const todayTrades = getTodaysTrades(trades);

  const context = useMemo(() => ({ search, isTodayOnly: todayTradesOnly, filters }), [todayTradesOnly, filters, search]);

  const processedTrades = useMemo(() => processTrades(trades, todayTradesOnly, search, filters), [filters, search, todayTradesOnly, trades]);
  const dividedTrades = divideTradesWithMultipleMaturityDateSubs(processedTrades.filteredTrades);

  return (
    <MainLayout>
      <div className="Trades mt-4">
        <TradesToolbar value={context} currentTrades={todayTrades} onSearchChange={onSearchChange} onTodayOnlyChange={onTodayOnlyChange} onFilterChange={onFilterChange} />

        {dividedTrades.length > 0 && <HeaderRow headerMaturityDateValue={headerMaturityDateValue} />}

        <TradesList
          setHeaderMaturityDateValue={setHeaderMaturityDateValue}
          dividedTrades={dividedTrades}
          spotDates={spotDates}
          onPredeliver={onPredeliver}
          onRollover={onRollover}
          onOpenCalendar={onOpenCalendar}
        />
      </div>
    </MainLayout>
  );
}
