import type { Action, Reducer } from '@reduxjs/toolkit';
import type {
  CalendarFieldsState,
  FxoPauseTargetAccumulatorBaseCalendarFields,
  FxoPermanentForwardAccumulatorBaseCalendarFields,
  FxoSquareTargetAccumulatorBaseCalendarFields,
  FxoStandardForwardAccumulatorBaseCalendarFields,
  FxTargetAccumulatorBaseCalendarFields,
} from '@/models/calendar';
import type { AccumulatorInstrumentName, AccumulatorTrade, XOneCalendarEntry } from '@/models/trade';
import { fxTargetAccumulatorFieldsConfig } from '@/App/calendar/fieldsComputation/fxTargetAccumulator';
import {
  fxoStandardForwardAccumulatorFieldsConfig,
} from '@/App/calendar/fieldsComputation/fxoStandardForwardAccumulator';
import { fxoSquareTargetAccumulatorFieldsConfig } from '@/App/calendar/fieldsComputation/fxoSquareTargetAccumulator';
import {
  fxoPermanentForwardAccumulatorFieldsConfig,
} from '@/App/calendar/fieldsComputation/fxoPermanentForwardAccumulator';
import { fxoPauseTargetAccumulatorFieldsConfig } from './fieldsComputation/fxoPauseTargetAccumulator';

export interface CalendarState {
  rows: CalendarFieldsState[];
  editingRowsId: number[];
}

const getConfigByInstrumentName = (instrumentName: AccumulatorInstrumentName, fxoForwardAccumulatorType?: 'standard' | 'permanent'): ReadonlyArray<keyof XOneCalendarEntry> => {
  switch (instrumentName) {
    case 'FxoTargetAccumulator':
      return fxTargetAccumulatorFieldsConfig;
    case 'FxoForwardAccumulator':
      return fxoForwardAccumulatorType === 'standard' ? fxoStandardForwardAccumulatorFieldsConfig : fxoPermanentForwardAccumulatorFieldsConfig;
    case 'FxoSquareTargetAccumulator':
      return fxoSquareTargetAccumulatorFieldsConfig;
    case 'FxoPauseTargetAccumulator':
      return fxoPauseTargetAccumulatorFieldsConfig;
    default:
      return [];
  }
};

// TODO : tighten type
const extractFieldsByInstrument = <T extends Record<string, string | number | undefined>>(
  rows: XOneCalendarEntry[],
  instrumentName: AccumulatorInstrumentName,
  fxoForwardAccumulatorType?: 'standard' | 'permanent',
): T[] => {
  const applicableFieldsName = getConfigByInstrumentName(instrumentName, fxoForwardAccumulatorType) as Array<keyof T>;

  return rows?.map((rowFields) =>
    applicableFieldsName.reduce((result, keptFieldName) => {
      const currentField = rowFields[keptFieldName as keyof XOneCalendarEntry];
      // eslint-disable-next-line no-param-reassign
      result[keptFieldName] = currentField as T[keyof T];
      return result;
    }, {} as T) ?? [],
  );
};

export const getFieldsByInstrument = (trade: AccumulatorTrade) => {
  const { xOneCalendar: rows, instrumentName } = trade;

  if (instrumentName === 'FxoForwardAccumulator') {
    if (trade.accumulatorType === 'standard') {
      return extractFieldsByInstrument<FxoStandardForwardAccumulatorBaseCalendarFields>(rows, instrumentName, trade.accumulatorType);
    }
    if (trade.accumulatorType === 'permanent') {
      return extractFieldsByInstrument<FxoPermanentForwardAccumulatorBaseCalendarFields>(rows, instrumentName);
    }
  }

  if (instrumentName === 'FxoTargetAccumulator') {
    return extractFieldsByInstrument<FxTargetAccumulatorBaseCalendarFields>(rows, instrumentName);
  }

  if (instrumentName === 'FxoSquareTargetAccumulator') {
    return extractFieldsByInstrument<FxoSquareTargetAccumulatorBaseCalendarFields>(rows, instrumentName);
  }
  if (instrumentName === 'FxoPauseTargetAccumulator') {
    return extractFieldsByInstrument<FxoPauseTargetAccumulatorBaseCalendarFields>(rows, instrumentName);
  }
  return [];
};

//
//
//
// ██████╗ ███████╗██████╗ ██╗   ██╗ ██████╗███████╗██████╗
// ██╔══██╗██╔════╝██╔══██╗██║   ██║██╔════╝██╔════╝██╔══██╗
// ██████╔╝█████╗  ██║  ██║██║   ██║██║     █████╗  ██████╔╝
// ██╔══██╗██╔══╝  ██║  ██║██║   ██║██║     ██╔══╝  ██╔══██╗
// ██║  ██║███████╗██████╔╝╚██████╔╝╚██████╗███████╗██║  ██║
// ╚═╝  ╚═╝╚══════╝╚═════╝  ╚═════╝  ╚═════╝╚══════╝╚═╝  ╚═╝

export const calendarLocalReducer: Reducer<CalendarState, CalendarAction> = (
  // eslint-disable-next-line default-param-last
  state = { rows: [], editingRowsId: [] },
  action: CalendarAction,
): CalendarState => {
  switch (action.type) {
    case 'RESET_CALENDAR':
      return {
        ...state,
        rows: getFieldsByInstrument(action.trade),
        editingRowsId: [],
      };
    case 'RESET_CALENDAR_ROW':
      return {
        ...state,
        editingRowsId: [...state.editingRowsId.filter((row) => row !== action.rowIndex)], // dedupe array
        rows: [...state.rows.slice(0, action.rowIndex), { ...state.rows[action.rowIndex], fixing: undefined }, ...state.rows.slice(action.rowIndex + 1)],
      };
    case 'EDIT_CALENDAR_FIXING':
      return {
        ...state,
        editingRowsId: [...new Set([...state.editingRowsId, action.rowIndex])], // dedupe array
        rows: [...state.rows.slice(0, action.rowIndex), { ...state.rows[action.rowIndex], fixing: action.value }, ...state.rows.slice(action.rowIndex + 1)],
      };
    default:
      return state;
  }
};

//
//
//
//  █████╗  ██████╗████████╗██╗ ██████╗ ███╗   ██╗
// ██╔══██╗██╔════╝╚══██╔══╝██║██╔═══██╗████╗  ██║
// ███████║██║        ██║   ██║██║   ██║██╔██╗ ██║
// ██╔══██║██║        ██║   ██║██║   ██║██║╚██╗██║
// ██║  ██║╚██████╗   ██║   ██║╚██████╔╝██║ ╚████║
// ╚═╝  ╚═╝ ╚═════╝   ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝

export type CalendarAction = SaveCalendarBaseFieldsAction | EditCalendarFixingAction | ResetCalendarRowAction;

type SaveCalendarBaseFieldsAction = Action<'RESET_CALENDAR'> & {
  trade: AccumulatorTrade;
};

export const resetCalendar = (trade: AccumulatorTrade): SaveCalendarBaseFieldsAction => ({
  type: 'RESET_CALENDAR',
  trade,
});

type EditCalendarFixingAction = Action<'EDIT_CALENDAR_FIXING'> & {
  rowIndex: number;
  value: number;
};

export const editCalendarFixingAction = (rowIndex: number, value: number): EditCalendarFixingAction => ({
  type: 'EDIT_CALENDAR_FIXING',
  rowIndex,
  value,
});

type ResetCalendarRowAction = Action<'RESET_CALENDAR_ROW'> & {
  rowIndex: number;
};

export const resetCalendarRowAction = (rowIndex: number): ResetCalendarRowAction => ({
  type: 'RESET_CALENDAR_ROW',
  rowIndex,
});
