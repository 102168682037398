import {
  type Dispatch,
  type SetStateAction,
  useCallback,
  useState,
} from "react";
import { FormattedMessage, useIntl } from "react-intl";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import { addDays, intlFormat, isBefore, isToday, isWeekend } from "date-fns";
import { enUS, fr } from "date-fns/locale";

import { CurrencySelector } from "@/App/Trader/components/CurrencySelector/CurrencySelector";

import {
  type CreateAlertFormData,
  getErrors,
  type CreateAlertStateForm,
} from "@/store/state/create-alert/create-alert.model";
import type { Currency } from "@/models/currency";
import { NumberInput } from "@/share/NumericInput/NumberInput";
import { getToday } from "@/utils/today";
import { capitalizeFirstLetter } from "@/utils/strings";

registerLocale("en", enUS);
registerLocale("fr", fr);

setDefaultLocale("en");

const today = getToday();

type AlertStateFormEditorProps = {
  alertForm: CreateAlertStateForm;
  currencies: readonly Currency[];
  createAlertChangeCurrency: (
    way: "base" | "contra",
    currency: Currency
  ) => void;
  createAlertFormUpdate: (p: Partial<CreateAlertFormData>) => void;
  fixingExample: string | undefined;
  onlyLimitPriceEditable?: boolean;
  isSameLimitPrice: boolean;
  setEditedLimitPrice: Dispatch<SetStateAction<number | null>>;
};

function DateDisplay({ date }: { date: number }) {
  const intl = useIntl();

  return (
    <p
      style={{
        fontSize: "18px",
        color: "#484848",
        fontWeight: "bolder",
        margin: 0,
      }}
    >
      {capitalizeFirstLetter(
        intlFormat(
          date,
          { month: "short", year: "numeric" },
          { locale: intl.locale }
        ).toLowerCase()
      )}
    </p>
  );
}

function CustomHeader({
  monthDate,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  customHeaderCount,
}: any) {
  return (
    <div
      style={{
        margin: 10,
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        marginBottom: "1rem",
      }}
    >
      <button
        type="button"
        onClick={decreaseMonth}
        disabled={prevMonthButtonDisabled}
        style={{
          display: "flex",
          borderRadius: "3px",
          border: "1px",
          borderStyle: "solid",
          borderColor: "#e4e7e7",
          backgroundColor: "#fff",
          color: "#757575",
          height: "33px",
          width: "39px",
          alignItems: "center",
          textAlign: "center",
          padding: "6px 9px",
          marginRight: "20px",
          visibility: customHeaderCount === 1 ? "hidden" : undefined,
        }}
      >
        <svg
          style={{
            height: "19px",
            width: "19px",
            fill: "#82888a",
            display: "block",
          }}
          focusable="false"
          viewBox="0 0 1000 1000"
        >
          <path d="M336 275L126 485h806c13 0 23 10 23 23s-10 23-23 23H126l210 210c11 11 11 21 0 32-5 5-10 7-16 7s-11-2-16-7L55 524c-11-11-11-21 0-32l249-249c21-22 53 10 32 32z" />
        </svg>
      </button>
      <div style={{ width: "35%" }}>
        <DateDisplay date={monthDate} />
      </div>

      <button
        type="button"
        onClick={increaseMonth}
        disabled={nextMonthButtonDisabled}
        style={{
          display: "flex",
          borderRadius: "3px",
          border: "1px",
          borderStyle: "solid",
          borderColor: "#e4e7e7",
          backgroundColor: "#fff",
          color: "#757575",
          height: "33px",
          width: "39px",
          alignItems: "center",
          textAlign: "center",
          padding: "6px 9px",
          marginLeft: "20px",
          visibility: customHeaderCount === 0 ? "hidden" : undefined,
        }}
      >
        <svg
          style={{
            height: "19px",
            width: "19px",
            fill: "#82888a",
            display: "block",
          }}
          focusable="false"
          viewBox="0 0 1000 1000"
        >
          <path d="M694 242l249 250c12 11 12 21 1 32L694 773c-5 5-10 7-16 7s-11-2-16-7c-11-11-11-21 0-32l210-210H68c-13 0-23-10-23-23s10-23 23-23h806L662 275c-21-22 11-54 32-33z"></path>
        </svg>
      </button>
    </div>
  );
}

export function AlertStateFormEditor({
  alertForm,
  createAlertChangeCurrency,
  createAlertFormUpdate,
  currencies,
  fixingExample,
  onlyLimitPriceEditable: onlyLimitPriceEditableProp,
  isSameLimitPrice,
  setEditedLimitPrice,
}: AlertStateFormEditorProps) {
  const errors = getErrors(alertForm.validation);

  const intl = useIntl();

  const onBaseCurrencyChange = useCallback(
    (baseCurrency: Currency) => {
      createAlertChangeCurrency("base", baseCurrency);
    },
    [createAlertChangeCurrency]
  );

  const onContraCurrencyChange = useCallback(
    (contraCurrency: Currency) => {
      createAlertChangeCurrency("contra", contraCurrency);
    },
    [createAlertChangeCurrency]
  );

  const onLimitPriceChange = useCallback(
    (limitPrice: string) => {
      setEditedLimitPrice(Number(limitPrice));
      createAlertFormUpdate({ limitPrice });
    },
    [createAlertFormUpdate]
  );

  const [isFocused, setFocused] = useState(false);
  const onFocusChange = useCallback(
    (focused: boolean | null) => {
      setFocused(focused ?? false);
    },
    [setFocused]
  );

  const onExpiryDateChange = useCallback(
    (expiryDate: Date | null) => {
      if (expiryDate === null) {
        return;
      }
      createAlertFormUpdate({ expiryDate: expiryDate });
    },
    [createAlertFormUpdate]
  );

  const onExpiryDateToggle = useCallback(() => {
    if (onlyLimitPriceEditableProp) {
      return;
    }
    if (alertForm.data.expiryDate === null) {
      createAlertFormUpdate({ expiryDate: addDays(new Date(), 1) });
    } else {
      createAlertFormUpdate({ expiryDate: null });
    }
  }, [createAlertFormUpdate, alertForm.data.expiryDate]);
  const onlyLimitPriceEditable = onlyLimitPriceEditableProp ?? false;

  const filterDate = (date: Date) =>
    !(isBefore(date, today) || isWeekend(date)) || isToday(date);

  return (
    <div className="col-lg-6 col-sm-12 mt-4">
      <div className="form-group mb-4">
        <label className="mb-2">
          <FormattedMessage id="alerts.yourcurrency" />
        </label>
        <CurrencySelector
          value={alertForm.data.baseCurrency}
          currencies={currencies}
          onCurrencyChange={onBaseCurrencyChange}
          disabled={onlyLimitPriceEditable === true}
        />
        {errors.baseCurrency.length !== 0 && (
          <span className="text-danger">
            {errors.baseCurrency[0].errorDescription}
          </span>
        )}
      </div>
      <div className="form-group mb-4">
        <label className="mb-2">
          <FormattedMessage id="alerts.against" />
        </label>
        <CurrencySelector
          value={alertForm.data.contraCurrency}
          currencies={currencies}
          onCurrencyChange={onContraCurrencyChange}
          disabled={onlyLimitPriceEditable === true}
        >
          <NumberInput
            onChange={onLimitPriceChange}
            className={`form-control col-md-6 text-end ${
              isSameLimitPrice ? "border-bottom border-danger" : " "
            }`}
            placeHolder={
              fixingExample !== undefined ? `ex. ${fixingExample}` : ""
            }
            e2eHandle="limit-price-"
            value={alertForm.data.limitPrice}
          />
        </CurrencySelector>
        {isSameLimitPrice && (
          <span className="d-flex justify-content-end text-danger mt-8px">
            <FormattedMessage id="alerts.sameLimitPrice" />
          </span>
        )}
        {errors.contraCurrency.length !== 0 && (
          <span className="text-danger">
            {errors.contraCurrency[0].errorDescription}
          </span>
        )}
      </div>
      <div className="form-group mb-0 ">
        <div className="row ">
          <label className="col-sm-8 mb-0">
            <FormattedMessage id="alerts.enableExpiryLabel" />
          </label>
          <div className="col-sm-4 ">
            <div className="form-check form-switch text-end d-flex flex-row justify-content-end  ">
              <input
                type="checkbox"
                onClick={onExpiryDateToggle}
                className="form-check-input"
                checked={alertForm.data.expiryDate !== null}
                readOnly={true}
                disabled={onlyLimitPriceEditable}
              />
              <label className="form-check-label m-4px">
                <FormattedMessage
                  id={
                    alertForm.data.expiryDate !== null
                      ? "alerts.enableExpiry.checked"
                      : "alerts.enableExpiry.unchecked"
                  }
                />
              </label>
            </div>
          </div>
        </div>
      </div>
      {alertForm.data.expiryDate !== null && (
        <div className="form-group mb-0 mt-4">
          <label className="mb-2">
            <FormattedMessage id="alerts.expiryDate" />
          </label>
          <div className="date-picker s">
            <DatePicker
              id="expiry_date"
              selected={alertForm.data.expiryDate}
              onChange={onExpiryDateChange}
              autoFocus={isFocused}
              onFocus={() => onFocusChange(true)}
              onBlur={() => onFocusChange(false)}
              dateFormat="d MMM yyyy"
              disabled={false}
              allowSameDay
              filterDate={filterDate}
              locale={intl.locale}
              popperPlacement="bottom-start"
              monthsShown={2}
              renderCustomHeader={CustomHeader}
            />
            <i className="icon icon-lg text-secondary ">date_range</i>
          </div>
          {errors.expiryDate.length !== 0 && (
            <span className="text-danger">
              <FormattedMessage id="alerts.expiryDate.isOnWeekend" />
            </span>
          )}
        </div>
      )}
    </div>
  );
}
