import { routes } from '@/App/routes';
import { FeatureToggle } from '@/share/FeatureToggle/FeatureToggle';
import { createAlertFormUpdate } from '@/store/state/create-alert/create-alert.actions';
import { selectRfs } from '@/store/state/rfs/rfs.selectors';
import { useAppDispatch } from '@/store/store';
import { useMatomo } from '@/utils/useMatomo';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

export function AlertsLink() {
  const { trackAction } = useMatomo();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { buyCurrency, sellCurrency } = useSelector(selectRfs);

  const onClick = useCallback(() => {
    trackAction('Gone to alerts from buySell page');

    dispatch(
      createAlertFormUpdate({
        baseCurrency: buyCurrency,
        contraCurrency: sellCurrency,
      }),
    );

    navigate(routes.CREATE_ALERT);
  }, [buyCurrency, sellCurrency, trackAction, dispatch, navigate]);

  return (
    <FeatureToggle feature="alerts">
      {(active) =>
        !active ? null : (
          <div className="mt-3">
            <FormattedMessage
              id="trader.goToAlerts.text"
              values={{
                link: (
                  <a className="text-link" onClick={onClick}>
                    <FormattedMessage id="trader.goToAlerts.link" />
                  </a>
                ),
              }}
            />
          </div>
        )
      }
    </FeatureToggle>
  );
}
