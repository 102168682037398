import type { MyHedgeTrade } from '@/models/trade';
import { useMatomo } from '@/utils/useMatomo';
import { isSameDay, parseISO } from 'date-fns';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';
import { MainLayout } from '../Layout/MainLayout';
import { routes } from '../routes';

export function EmptyUpcomingFlows({ tradesData }: { tradesData: MyHedgeTrade[] }) {
  const { trackAction } = useMatomo();
  const navigate = useNavigate();

  const goToTrade = useCallback(() => {
    trackAction('Gone to sellOrBuy from upcoming flows');
    navigate(routes.TRADE);
  }, [trackAction, navigate]);

  const isSomeTradesDateSameAsTodaysDate = tradesData.some((trade) => isSameDay(parseISO(trade.date), new Date()));

  return (
    <MainLayout>
      <div className="text-center text-secondary mt-5">
        <i className="icon icon-md">info_outline</i>

        <h3 className="mb-2">
          <FormattedMessage id={`app.portfolio.upcomingFlows.${isSomeTradesDateSameAsTodaysDate ? 'coming' : 'empty'}.MESSAGE_LINE_1`} />
        </h3>

        <div className="text-regular fw-medium text-secondary">
          <FormattedMessage id={`app.portfolio.upcomingFlows.${isSomeTradesDateSameAsTodaysDate ? 'coming' : 'empty'}.MESSAGE_LINE_2`} />
        </div>

        <button type="button" className="btn btn-primary mt-4 btn-lg" onClick={goToTrade}>
          <FormattedMessage id="app.portfolio.upcomingFlows.empty.SELL_BUY_BUTTON" />
        </button>
      </div>
    </MainLayout>
  );
}
