import { isPredeliverRoute, isRfsPage, isRolloverRoute } from '@/App/routes';
import type { Rfs } from '@/models/rfs';
import { cancelStreaming } from '@/services/trade.service';
import { type CloseLastErrorAction, closeLastErrorAction } from '@/store/state/errors/errors.actions';
import type { TrackRfq } from '@/utils/useMatomo';
import { type Epic, ofType } from 'redux-observable';
import { type Observable, of } from 'rxjs';
import { filter, map, mergeMap, pairwise, tap, withLatestFrom } from 'rxjs/operators';
import type { AppAction } from '../app.actions';
import type { AppState } from '../app.state';
import { type LocationChangeAction, type ResetAction, type StreamStoppedAction, resetAction, streamStoppedAction } from '../state/rfs/rfs.actions';

const doPiwik = (trackRfq: TrackRfq, rfs: Rfs) => {
  if (rfs.status !== 'idle' && rfs.status !== 'starting-request' && rfs.status !== 'cancelling') {
    if (rfs.product === 'FxPredeliver') {
      trackRfq('Execution abandoned', 'Abandoned predeliver on forward');
    } else if (rfs.product === 'FxSpot') {
      trackRfq('Execution abandoned', 'Abandoned spot');
    } else if (rfs.product === 'FxFwd') {
      trackRfq('Execution abandoned', 'Abandoned forward');
    }
  }
};

export const pageChangeEpic =
  (trackRfq: TrackRfq, cancelRfs = cancelStreaming): Epic<AppAction, AppAction, AppState> =>
  (action$, state$) =>
    action$.pipe(
      ofType<AppAction, LocationChangeAction>('LOCATION_CHANGE'),
      filter((action) => !isPredeliverRoute(action.pathname) && !isRolloverRoute(action.pathname)),
      withLatestFrom(state$.pipe(pairwise())), // pair with old state, to get the previous route
      mergeMap(([, [{ ui }, { rfs }]]) =>
        rfs.status !== 'idle' && isRfsPage(ui.router.current!)
          ? cancelRfs(rfs.id).pipe(
              tap(() => doPiwik(trackRfq, rfs)),
              map((hasPreviouslyCanceled) => ({ hasPreviouslyCanceled, rfsId: rfs.id })),
            )
          : of({ hasPreviouslyCanceled: false }),
      ),
      mergeMap<{ hasPreviouslyCanceled: boolean; rfsId?: string }, Observable<ResetAction | CloseLastErrorAction | StreamStoppedAction>>(({ hasPreviouslyCanceled, rfsId }) =>
        hasPreviouslyCanceled && rfsId ? of(streamStoppedAction(rfsId), resetAction(), closeLastErrorAction()) : of(resetAction(), closeLastErrorAction()),
      ),
    );
