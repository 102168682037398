import type { AppAction } from '@/store/app.actions';
import { extractError } from '@/utils/errors';
import type { Reducer } from '@reduxjs/toolkit';

export interface AppCrash {
  error: string | null;
}

export const DEFAULT_APP_CRASH_STATE: AppCrash = { error: null };

// biome-ignore lint/style/useDefaultParameterLast: will be fixed with RTK conversion
export const appCrashReducer: Reducer<AppCrash, AppAction> = (state = DEFAULT_APP_CRASH_STATE, action): AppCrash => {
  switch (action.type) {
    case 'APP_CRASHED':
      return {
        ...state,
        error: !state.error ? extractError(action.error) ?? `internal error in ${action.source}` : state.error,
      };

    default:
      return state;
  }
};
