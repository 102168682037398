import { localeChangedAction } from '@/store/state/ui/ui.actions';
import { selectShowNavigateAsModal } from '@/store/state/ui/ui.selectors';
import { blockExecutionThunk } from '@/store/state/ui/ui.thunks';
import { selectNavigableAsUsers, selectNavigateAsUser } from '@/store/state/user/user.selectors';
import { navigateAsUserThunk } from '@/store/state/user/user.thunks';
import { useAppDispatch } from '@/store/store';
import type { Locale } from '@/utils/locale';
import type { SgwtWidgetUser } from '@sgwt/sgwt-widgets-react/dist/utils';
/* eslint-disable jsx-a11y/control-has-associated-label */
import * as React from 'react';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { AccountCenter } from './AccountCenter';
import { DebugHeader } from './DebugHeader';
import HeaderBrand from './HeaderBrand';
import HeaderCollapsedMenu from './HeaderCollapsedMenu';
import HeaderTabs from './HeaderTabs';

interface HeaderDispatchedHandlers {
  changeLocale: (locale: Locale) => void;
  navigateAs: (user: SgwtWidgetUser) => void;
  stopNavigateAs: () => void;
  onBlockExecution: (blockExecution: boolean) => void;
}

const useDispatchedHandlers = (): HeaderDispatchedHandlers => {
  const dispatch = useAppDispatch();

  return useMemo(() => {
    // console.log(`=== useDispatchedHandlers`);
    return {
      changeLocale: (locale: Locale) => dispatch(localeChangedAction(locale)),
      navigateAs: (user: SgwtWidgetUser) => dispatch(navigateAsUserThunk(user.email as string)), // in the type, le property is "mail", but the real name is email
      stopNavigateAs: () => dispatch(navigateAsUserThunk(undefined)),
      onBlockExecution: (blockExecution: boolean) => dispatch(blockExecutionThunk(blockExecution)),
    };
  }, [dispatch]);
};

export function Header() {
  const navigatedAsUser = useSelector(selectNavigateAsUser);
  const showModal = useSelector(selectShowNavigateAsModal);
  const users = useSelector(selectNavigableAsUsers);

  const { changeLocale, navigateAs, stopNavigateAs, onBlockExecution } = useDispatchedHandlers();

  React.useEffect(() => {
    const visibilityChange = () => onBlockExecution(document.hidden);
    const hidden = () => onBlockExecution(true);
    const visible = () => {
      setTimeout(() => onBlockExecution(false), 100);
    };
    document.addEventListener('visibilitychange', visibilityChange);
    window.addEventListener('focus', visible);
    window.addEventListener('blur', hidden);
    window.addEventListener('pageshow', visible);
    window.addEventListener('pagehide', hidden);
    return () => {
      document.removeEventListener('visibilitychange', visibilityChange);
      window.removeEventListener('focus', visible);
      window.removeEventListener('blur', hidden);
      window.removeEventListener('pageshow', visible);
      window.removeEventListener('pagehide', hidden);
    };
  }, [onBlockExecution]);

  return (
    <header>
      <nav className="navbar">
        <DebugHeader />

        <div className="navbar-title">
          <HeaderBrand />
          <HeaderCollapsedMenu />
        </div>

        <div className="navbar-content navbar-content-container me-5">
          <ul className="navbar-navigation">
            <HeaderTabs />
          </ul>
        </div>

        <div className="navbar-toolbar">
          <AccountCenter
            navigateAs={users !== undefined || undefined}
            navigateAsList={users}
            navigateAsUser={navigatedAsUser}
            languageChanged={changeLocale as (locale: string) => void}
            navigateAsSelectUser={navigateAs}
            stopNavigationAs={stopNavigateAs}
            showNavigateAsModal={showModal}
            blockExecution={onBlockExecution}
          />
        </div>
      </nav>
    </header>
  );
}
