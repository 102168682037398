import { type RouteValues, isRfsPage, routes } from '@/App/routes';
import type { Feature } from '@/config/app.config';
import type { AppAction } from '@/store/app.actions';
import { type Locale, initLocale } from '@/utils/locale';
import type { Reducer } from '@reduxjs/toolkit';
import { isDateInvalid } from '../errors/errors.selectors';

export interface UiState {
  currentLocale: Locale;
  showNavigateAsModal: boolean;
  blockExecution: boolean;
  canForward: boolean;
  closedDates: {
    [currencyPair: string]: string[];
  };
  loadingAlerts: boolean;
  loadingAlertsFailed: boolean;
  featureToggles: Record<Feature, boolean>;
  spotDate: SpotDateUiState;
  router: { current: string; previous: string };
  pendingRedirectionUrl?: RouteValues;
}

export type SpotDateUiState = 'EMPTY' | 'REQUESTED' | 'RECEIVED' | 'ERROR';

export const DEFAULT_UI_STATE: UiState = {
  currentLocale: initLocale(),
  showNavigateAsModal: false,
  blockExecution: false,
  canForward: true,
  closedDates: {},
  loadingAlerts: false,
  loadingAlertsFailed: false,
  featureToggles: {
    alerts: false,
    predeliver: false,
    rollover: false,
    forwardAccumulator: false,
    squareTargetAccumulator: false,
    targetAccumulator: false,
    pauseTargetAccumulator: false,
    vanillaAccumulator: false,
  },
  spotDate: 'EMPTY',
  router: { current: routes.HOME, previous: routes.HOME },
};

export const uiReducer: Reducer<UiState, AppAction> = (
  // biome-ignore lint/style/useDefaultParameterLast: will be fixed with RTK conversion
  state = DEFAULT_UI_STATE,
  action,
): UiState => {
  switch (action.type) {
    case 'USER_LOADED':
      return {
        ...state,
        featureToggles: {
          ...action.user.featureToggles,
          alerts: action.user.hasCallRight,
        },
        loadingAlerts: action.user.hasCallRight,
      };

    case 'LOCALE_CHANGED':
      return { ...state, currentLocale: action.locale ?? state.currentLocale };

    case 'TOGGLE_NAVIGATE_AS':
      return { ...state, showNavigateAsModal: !state.showNavigateAsModal };

    case 'CLOSED_DATES_RECEIVED':
      return {
        ...state,
        closedDates: { ...state.closedDates, [action.currencyPair]: action.dates },
      };

    case 'BLOCK_EXECUTION':
      if (state.blockExecution === action.blockExecution) {
        return state;
      }
      return {
        ...state,
        blockExecution: action.blockExecution,
      };

    case 'LOADING_ALERTS':
      return {
        ...state,
        loadingAlerts: true,
      };

    case 'ALERTS_LOADED':
      return {
        ...state,
        loadingAlerts: false,
      };

    case 'ALERTS_LOADING_FAILED':
      return {
        ...state,
        loadingAlerts: false,
        loadingAlertsFailed: true,
      };

    case 'SPOT_DATE_RECEIVED':
      return {
        ...state,
        spotDate: 'RECEIVED',
      };

    case 'SPOT_DATE_ERROR':
      return {
        ...state,
        spotDate: 'ERROR',
      };

    case 'LOCATION_CHANGE':
      return {
        ...state,
        spotDate: isRfsPage(action.pathname) ? 'EMPTY' : state.spotDate,

        router: {
          previous: state.router.current,
          current: action.pathname,
        },
      };

    // We don't know the access rights per counterparty, so we detect
    // that through a RFS error and reset it on counterparty change.
    case 'ADD_ERROR':
      if (isDateInvalid([action.error]) && state.canForward) {
        return { ...state, canForward: false };
      }
      return state;

    case 'CHANGE_COMPANY':
      if (!state.canForward) {
        return { ...state, canForward: true };
      }
      return state;

    case 'REQUEST_REDIRECT':
      return { ...state, pendingRedirectionUrl: action.toUrl };

    case 'CANCEL_REDIRECT':
      return { ...state, pendingRedirectionUrl: undefined };

    default:
      return state;
  }
};
