import type { Company } from '@/models/user';
import { useMatomo } from '@/utils/useMatomo';
import { isDefined, isNotDefined } from '@sgme/fp';
import { useSgwtWidgets } from '@sgwt/sgwt-widgets-react';
import { useEffect } from 'react';

export const useSgwtWidgetLogs = () => {
  const { trackAction } = useMatomo();

  const { sgwtAccountCenter, sgwtHelpCenter } = useSgwtWidgets();

  useEffect(() => {
    if (isNotDefined(sgwtAccountCenter) || isNotDefined(trackAction)) {
      return;
    }

    const onOpenedAccountCenter = () => {
      trackAction('Opened account center');
    };

    const onClosedAccountCenter = () => {
      trackAction('Closed account center');
    };

    const onOpenedNotificationCenter = () => {
      trackAction('Opened notification center');
    };

    const onClosedNotificationCenter = () => {
      trackAction('Opened my services board');
    };

    const onOpenedServiceBoard = () => {
      trackAction('Closed my services board');
    };

    const onClosedServiceBoard = () => {
      trackAction('Closed my services board');
    };

    sgwtAccountCenter.addEventListener('sgwt-account-center--show-user-card', onOpenedAccountCenter);
    sgwtAccountCenter.addEventListener('sgwt-account-center--hide-user-card', onClosedAccountCenter);
    sgwtAccountCenter.addEventListener('sgwt-account-center--show-notification-card', onOpenedNotificationCenter);
    sgwtAccountCenter.addEventListener('sgwt-account-center--hide-notification-card', onClosedNotificationCenter);
    sgwtAccountCenter.addEventListener('sgwt-account-center--show-my-services-card', onOpenedServiceBoard);
    sgwtAccountCenter.addEventListener('sgwt-account-center--hide-my-services-card', onClosedServiceBoard);

    return () => {
      sgwtAccountCenter.removeEventListener('sgwt-account-center--show-user-card', onOpenedAccountCenter);
      sgwtAccountCenter.removeEventListener('sgwt-account-center--hide-user-card', onClosedAccountCenter);
      sgwtAccountCenter.removeEventListener('sgwt-account-center--show-notification-card', onOpenedNotificationCenter);
      sgwtAccountCenter.removeEventListener('sgwt-account-center--hide-notification-card', onClosedNotificationCenter);
      sgwtAccountCenter.removeEventListener('sgwt-account-center--show-my-services-card', onOpenedServiceBoard);
      sgwtAccountCenter.removeEventListener('sgwt-account-center--hide-my-services-card', onClosedServiceBoard);
    };
  }, [sgwtAccountCenter, trackAction]);

  useEffect(() => {
    if (isNotDefined(sgwtHelpCenter) || isNotDefined(trackAction)) {
      return;
    }

    const onOpenedHelpCenter = () => {
      trackAction('Opened account center');
    };

    const onClosedHelpCenter = () => {
      trackAction('Closed help center');
    };

    sgwtHelpCenter.addEventListener('sgwt-help-center--open', onOpenedHelpCenter);
    sgwtHelpCenter.addEventListener('sgwt-help-center--close', onClosedHelpCenter);

    return () => {
      sgwtHelpCenter.removeEventListener('sgwt-account-center--show-user-card', onOpenedHelpCenter);
      sgwtHelpCenter.removeEventListener('sgwt-help-center--close', onClosedHelpCenter);
    };
  }, [sgwtHelpCenter, trackAction]);
};

const formatCompanyName = (company: Company | undefined) => {
  return isDefined(company) ? `${company.companyBdrId} - ${company.companyName}` : '';
};
