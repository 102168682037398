import { Navigate, Route, RouterProvider, Routes, useLocation } from 'react-router';
import './App.scss';
import { FeatureToggle } from '@/share/FeatureToggle/FeatureToggle';
import { locationChangeAction } from '@/store/state/rfs/rfs.actions';
import { useAppDispatch } from '@/store/store';
import { useSgwtWidgetLogs } from '@/utils/useSgwtWidgetLogs';
import { useEffect } from 'react';
import { createBrowserRouter } from 'react-router-dom';
import { CreateAlertForm } from './Alerts/AlertForms/CreateAlertForm';
import { UpdateAlertForm } from './Alerts/AlertForms/UpdateAlertForm';
import { Alerts } from './Alerts/Alerts';
import { AppCrash } from './AppCrash';
import { ContactUs } from './ContactUs/ContactUs';
import { Footer } from './Footer/Footer';
import { Header } from './Header/Header';
import { HelpCenter } from './HelpCenter/HelpCenter';
import { Home } from './Home/Home';
import { Predeliver } from './Operation/Predeliver/Predeliver';
import { Rollover } from './Operation/Rollover/Rollover';
import { Trader } from './Trader/Trader';
import { Trades } from './Trades/Trades';
import { UpcomingFlows } from './UpcomingFlows';
import { CalendarPage } from './calendar';
import { routes } from './routes';
import { SessionDisconnectedModal } from './utils/SessionDisconnectModal';

function ProtectWithAlertsRight(Component: React.ComponentType) {
  return <FeatureToggle feature="alerts">{(active) => (active ? <Component /> : <Navigate to={routes.HOME} replace />)}</FeatureToggle>;
}

const router = createBrowserRouter([{ path: '*', Component: Root }]);

export function App() {
  return <RouterProvider router={router} />;
}

export function Root() {
  const location = useLocation();
  const dispatch = useAppDispatch();

  useSgwtWidgetLogs();

  useEffect(() => {
    dispatch(locationChangeAction(location.pathname));
  }, [location, dispatch]);

  return (
      <AppCrash>
        <HelpCenter />

        <div className="App">
          <Header />

          <Routes>
            <Route path={routes.TRADE} element={<Trader />} />
            <Route path={routes.CONTACT} element={<ContactUs />} />
            <Route path={routes.PORTFOLIO} element={<Trades />} />
            <Route path={routes.UPCOMING_FLOWS} element={<UpcomingFlows />} />
            <Route path={routes.CALENDAR} element={<CalendarPage />} />
            <Route path={routes.USE_ENVELOPPE} element={<Predeliver />} />
            <Route path={routes.REPORT_ENVELOPPE} element={<Rollover />} />
            <Route path={routes.ALERTS} element={ProtectWithAlertsRight(Alerts)} />
            <Route path={routes.CREATE_ALERT} element={ProtectWithAlertsRight(CreateAlertForm)} />
            <Route path={routes.UPDATE_ALERT} element={ProtectWithAlertsRight(UpdateAlertForm)} />
            <Route path={routes.HOME} element={<Home />} />
            <Route path="*" element={<Home />} />
          </Routes>

          <SessionDisconnectedModal />

          <Footer />
        </div>
      </AppCrash>
  );
}
