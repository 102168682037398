import { logger } from '@/logging/logger';
import { getSgwtConnect } from '@/sgwtConnect';
import type { AppState } from '@/store/app.state';
import { http } from '@/utils/http';
import { type Epic, ofType } from 'redux-observable';
import { EMPTY, type Observable, TimeoutError, of, throwError } from 'rxjs';
import type { AjaxResponse } from 'rxjs/ajax';
import { catchError, ignoreElements, mergeMap, take, timeout, withLatestFrom } from 'rxjs/operators';
import { type IHelpRequestAdditionalParams, sendHelpRequest } from '../../services/helpRequest.service';
import type { AppAction } from '../app.actions';
import { type ExecuteAction, type ExecutedAction, type ExecutionFailedAction, type TimeoutedDealAction, executionTimeoutAction } from '../state/rfs/rfs.actions';

export const sendTimeoutedExecutionMail = (state: AppState): Observable<AjaxResponse> => {
  if (state.rfs.status !== 'executing') {
    throw new Error("Execution email can only be sent when rfs status is 'executing'");
  }

  const details = {
    selectedCompanyBrdId: state.user.selectedCompany !== undefined ? state.user.selectedCompany.companyBdrId : undefined,
    selectedCompanyName: state.user.selectedCompany !== undefined ? state.user.selectedCompany.companyName : undefined,
    tradeDate: state.rfs.date,
    currencyPair: `${state.rfs.buyCurrency}/${state.rfs.sellCurrency}`,
  };

  const payload: IHelpRequestAdditionalParams = {
    config: 'timeouteddeal',
    application: 'SGME-MY-FX',
    details: JSON.stringify(details),
    sendConfirmation: false,
    data: { multipassId: state.rfs.id },
  };

  return sendHelpRequest(http, getSgwtConnect())(payload);
};

export const monitorPendingExecutionEpic =
  (timeoutInMs = 10000): Epic<AppAction, AppAction, AppState> =>
  (action$, state$) =>
    action$.pipe(
      ofType<AppAction, ExecuteAction>('EXECUTE'),
      withLatestFrom(state$),
      mergeMap(([_, state]) =>
        action$.pipe(
          ofType<AppAction, ExecutedAction | ExecutionFailedAction | TimeoutedDealAction>('EXECUTED', 'EXECUTION_FAILED', 'TIMEOUTED_DEAL'),
          take(1),
          timeout(timeoutInMs),
          ignoreElements(),
          catchError((error) => {
            if (!(error instanceof TimeoutError)) {
              return throwError(error);
            }

            sendTimeoutedExecutionMail(state)
              .pipe(mergeMap((__) => EMPTY))
              .subscribe();

            logger.logWarning('PendingExecutionTimeout', {
              rfsId: state.rfs.status === 'executing' ? state.rfs.id : '',
            });

            return of(executionTimeoutAction());
          }),
        ),
      ),
    );
