import { SGWTConnectCore } from '@sgwt/connect-core';
import { Http } from '@/utils/http';
import { catchError } from 'rxjs/operators';
import { logger } from '@/logging/logger';
import { throwError } from 'rxjs';

export type HelpRequestConfig =
  | 'general'
  | 'access'
  | 'appcrashed'
  | 'dealpending'
  | 'timeouteddeal'
  | 'orderpending'; // support.address.<config> must exist in XOne.Host App.config

export interface IHelpRequestAdditionalParams {
  application: 'SGME-MY-FX';
  config?: HelpRequestConfig;
  details?: string;
  sendConfirmation?: boolean;
  lastActions?: string;
  state?: string;
  data?: object;
  screenshot?: string;
}

export const sendHelpRequest =
  (http: Http, sgwtConnectParam: SGWTConnectCore) =>
    (helpRequestParam: IHelpRequestAdditionalParams) => {
      const { userAgent } = navigator;

      // user info is extracted from sgwtConnect
      const login = sgwtConnectParam.getIdTokenClaims()!.sub as string;
      const payload = {
        ...helpRequestParam,
        login,
        userAgent,
      };

      try {
        logger.logInformation('Sending help request {@helpRequestBody}', {
          login,
          userAgent,
          helpRequestParam
        });
      } catch (err) {
        logger.logError('Log info failed, need to reduce helpRequestBody')
      }

      return http.post('help/request', payload, {
        'Content-Type': 'application/json',
      }).pipe(
        catchError((err) => {
          logger.logError('Attempt #1 Error while sending help request {message_s}', err.message);
          if (err.status === 413) {
            delete payload.state;
            payload.details =
              `[state missing because of error 413, state size was ${helpRequestParam?.state?.length}]\n\n` +
              (helpRequestParam?.details || '');
            return http.post('help/request', payload, {
              'Content-Type': 'application/json',
            });
          } else {
            return throwError(err);
          }
        }),
        catchError((err) => {
          logger.logError('Attempt #2 Error while sending help request {message_s}', err.message);
          if (err.status === 413) {
            delete payload.screenshot;
            payload.details =
              `[state and screenshot missing because of error 413, state size was ${helpRequestParam?.state?.length} and screenshot size was ${helpRequestParam?.screenshot?.length}]\n\n` +
              (helpRequestParam?.details || '');
            return http.post('help/request', payload, {
              'Content-Type': 'application/json',
            });
          } else {
            return throwError(err);
          }
        }),
        catchError((err) => {
          logger.logError('Attempt #3 - Last retry - Error while sending help request {message_s}', err.message);
          delete payload.lastActions;
          payload.details =
            `[Removed: state, screenshot, lastAction, because of previous error 413]\n\n` +
            (helpRequestParam?.details || '');
          return http.post('help/request', payload, {
            'Content-Type': 'application/json',
          });
        }),
      );
    };
