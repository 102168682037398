import { logger } from '@/logging/logger';
import type { Alert } from '@/models/alerts';
import type { AppAction } from '@/store/app.actions';
import type { Reducer } from '@reduxjs/toolkit';

export type AlertItem = Alert & {
  isDeleting: boolean;
};

export const alertToItem = (alert: Alert) => ({
  ...alert,
  isDeleting: false,
});

export type AlertsState = Record<string, AlertItem>;

const updateItem = (state: Record<string, AlertItem>, id: string, patcher: (current: AlertItem) => Partial<AlertItem>) => {
  if (state[id] === undefined) {
    return state;
  }
  const { [id]: current, ...rest } = state;
  return { ...rest, [id]: { ...current, ...patcher(current) } };
};

const handleAlertReceived = (state: AlertsState, alert: Alert): AlertsState => {
  const shouldSkip = state[alert.id] && state[alert.id].updateTime >= alert.updateTime;

  if (shouldSkip) {
    logger.logInformation('Skipping old alert notification', alert);
  }
  return shouldSkip ? state : { ...state, [alert.id]: alertToItem(alert) };
};

export const DEFAULT_ALERT_STATE: AlertsState = {};

export const alertsReducer: Reducer<AlertsState, AppAction> = (
  // biome-ignore lint/style/useDefaultParameterLast: will be fixed with RTK conversion
  state = DEFAULT_ALERT_STATE,
  action,
): AlertsState => {
  switch (action.type) {
    case 'ALERTS_LOADED':
      return action.alerts.reduce(
        (acc, alert) => {
          acc[alert.id] = alertToItem(alert);
          return acc;
        },
        {} as { [key: Alert['id']]: ReturnType<typeof alertToItem> },
      );
    case 'ALERT_RECEIVED':
      return handleAlertReceived(state, action.alert);
    case 'DeleteAlert':
      return updateItem(state, action.id, () => ({ isDeleting: true }));
    case 'FinishedDeleteAlert':
      return updateItem(state, action.id, () => ({ isDeleting: false }));
    default:
      return state;
  }
};
