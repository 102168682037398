import { logger } from '@/logging/logger';
import type { StreamingInfo } from '@/models/streaming';
import { getFakeUser, getSgwtConnect } from '@/sgwtConnect';
import { type HubConnection, HubConnectionBuilder, type ILogger, type IRetryPolicy, LogLevel, type RetryContext } from '@microsoft/signalr';
import { MessagePackHubProtocol } from '@microsoft/signalr-protocol-msgpack';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { noop } from '../utils/noop';
import type {
  BlotterOrderEvent,
  BlotterTradeEvent,
  DealRejectedEvent,
  DealReplyEvent,
  DisconnectEvent,
  FillReportEvent,
  PingEvent,
  QuoteAbortEvent,
  QuoteReplyEvent,
} from './streaming.model';

const { signalR } = window.sgmeConfiguration;

const signalRLogFormatter = (message?: any, ...optionalParams: any[]) => `[SIGNALR]: ${message} ${optionalParams}`;

class SignalRLogger implements ILogger {
  private static mapper: Record<LogLevel, (message?: any, ...optionalParams: any[]) => void> = {
    [LogLevel.Trace]: logger.logInformation,
    [LogLevel.Debug]: logger.logDebug,
    [LogLevel.Information]: logger.logInformation,
    [LogLevel.Warning]: logger.logWarning,
    [LogLevel.Error]: logger.logError,
    [LogLevel.Critical]: logger.logError,
    [LogLevel.None]: logger.logInformation,
  };

  public log(logLevel: LogLevel, message: string) {
    if (logLevel <= LogLevel.Debug) {
      return;
    }
    const sRlogger = SignalRLogger.mapper[logLevel];
    const formattedMessage = signalRLogFormatter(message);
    sRlogger(formattedMessage);
  }
}

let connectionId: string;
let ping$: Observable<PingEvent>;
let quoteReply$: Observable<QuoteReplyEvent>;
let quoteAbort$: Observable<QuoteAbortEvent>;
let dealRejected$: Observable<DealRejectedEvent>;
let dealReply$: Observable<DealReplyEvent>;
let fillReport$: Observable<FillReportEvent>;
let disconnect$: Observable<DisconnectEvent>;
let blotterTrade$: Observable<BlotterTradeEvent>;
let blotterOrder$: Observable<BlotterOrderEvent>;

let driver: 'WEBSOCKET' | 'LongPollingTransport';

const createHubConnection = () => {
  const fakeUser = getFakeUser();
  const hubUrl = `${signalR.serverUrl}/${signalR.hubName}/${fakeUser ? `?user=${fakeUser}` : ''}`;
  const getAccessToken = () => getSgwtConnect().getAuthorizationHeader()?.replace('Bearer ', '') ?? '';
  const sRlogger = new SignalRLogger();
  const retryPolicy: IRetryPolicy = {
    nextRetryDelayInMilliseconds: (retryContext: RetryContext) => {
      logger.logWarning(
        `Attempt to auto reconnect. Retry count: ${retryContext.previousRetryCount}. Time spend reconnecting in ms: ${retryContext.elapsedMilliseconds}. Reason: ${retryContext.retryReason.stack}`,
      );

      if (retryContext.elapsedMilliseconds < 60 * 1000) {
        return signalR.reconnectDelay;
      } else if (retryContext.elapsedMilliseconds < 10 * 60 * 1000) {
        return signalR.reconnectDelay * 10;
      } else {
        return signalR.reconnectDelay * 100;
      }
    },
  };

  return new HubConnectionBuilder()
    .withUrl(hubUrl, {
      accessTokenFactory: getAccessToken,
    })
    .withHubProtocol(new MessagePackHubProtocol())
    .withAutomaticReconnect(retryPolicy)
    .configureLogging(sRlogger)
    .build();
};

function createStreamFromSignalR<T>(hubConnection: HubConnection, methodName: string, eventType: string): Observable<T> {
  const stream$ = new Observable<T>((subscriber) => {
    hubConnection.on(methodName, (data) => {
      subscriber.next({ type: eventType, ...data });
    });
  }).pipe(share());
  stream$.subscribe();
  return stream$;
}

const connectToSignalR = (resolve: (value: StreamingInfo | PromiseLike<StreamingInfo>) => void, reject: (reason?: any) => void) => {
  logger.logInformation(signalRLogFormatter('trying to connect to SignalR with config'), signalR);

  const hubConnection = createHubConnection();
  hubConnection.keepAliveIntervalInMilliseconds = 10 * 1000;
  hubConnection.serverTimeoutInMilliseconds = 12 * 1000; // timeout not too aggressive, not too large to trigger timeout followed by a reconnect to another ASR

  ping$ = createStreamFromSignalR(hubConnection, 'ReceivePing', 'PING');
  quoteReply$ = createStreamFromSignalR(hubConnection, 'ReceiveQuoteReply', 'QUOTE.REPLY');
  quoteAbort$ = createStreamFromSignalR(hubConnection, 'ReceiveQuoteAbort', 'QUOTE.ABORT');
  dealRejected$ = createStreamFromSignalR(hubConnection, 'ReceiveDealRejected', 'DEAL.REJECTED');
  dealReply$ = createStreamFromSignalR(hubConnection, 'ReceiveDealReply', 'DEAL.REPLY');
  fillReport$ = createStreamFromSignalR(hubConnection, 'ReceiveFillReport', 'FILL.REPORT');
  disconnect$ = createStreamFromSignalR(hubConnection, 'ReceiveForceLogOff', 'DISCONNECTION.NOTIFICATION');
  blotterTrade$ = createStreamFromSignalR(hubConnection, 'ReceiveTradeNotification', 'BLOTTER.TRADE');
  blotterOrder$ = createStreamFromSignalR(hubConnection, 'ReceiveOrderNotification', 'BLOTTER.ORDER');

  hubConnection.on('ReceiveMessage', (data) => {
    if (data !== 'START') {
      logger.logWarning(signalRLogFormatter('received data on old callback'), data);
    }
  });
  // TODO: handle heartbeat to detect missing price
  hubConnection.on('ReceiveHeartbeat', noop);
  hubConnection.on('ReceiveConnection', (conId) => {
    // @ts-ignore, TODO: change this if the signalR api offer a public way to get the transport type
    const { transport } = hubConnection.connection;
    driver =
      // eslint-disable-next-line no-underscore-dangle
      transport.webSocket !== undefined || transport._webSocket !== undefined ? 'WEBSOCKET' : 'LongPollingTransport';
    logger.logInformation('Streaming connected with', 'SignalR', driver);

    resolve({
      connectionId: conId,
      protocol: 'SignalR',
      transport: driver,
    });
  });

  let initialReconnect = 0;
  const maxRetries = 3;
  const retryDelay = 3000;

  const start = () => ((window as any)['____SignalR'] = hubConnection);

  hubConnection.start().catch((error) => {
    logger.logError(signalRLogFormatter('Failed to make the initial connection to SignalR.'), error);
    if (initialReconnect < maxRetries) {
      initialReconnect += 1;
      logger.logWarning(`Failed to make the initial connection to SignalR with error: ${error}.
          Will try to reconnect after ${retryDelay} ms, ${initialReconnect}/${maxRetries} tries.`);
    } else {
      logger.logError(`Failed to make the initial connection to SignalR with error: ${error}`);
      reject(error);
    }
  });

  hubConnection.onreconnecting((error) => {
    logger.logWarning(`SignalR connection lost due to error "{message_s}". Reconnecting.`, error?.message);
  });

  hubConnection.onreconnected((connectionId) => {
    logger.logWarning(`SignalR reconnected with "{connectionId_s}"`, connectionId);
  });

  hubConnection.onclose(() => {
    logger.logError('SignalR emit a CLOSE event, it will not try to reconnect anymore !!!');
    setTimeout(() => start(), retryDelay);
  });

  start();
};

export const getDriver = () => driver;

export const getConnectionId = () => connectionId;

export const connectToStreaming = () =>
  new Promise<StreamingInfo>((resolve, reject) => {
    connectToSignalR(resolve, reject);
  });

export const getStreaming$ = () => ({
  ping$,
  quoteReply$,
  quoteAbort$,
  dealRejected$,
  dealReply$,
  fillReport$,
  blotterTrade$,
  blotterOrder$,
  disconnect$,
});
