import { Currency } from '@/models/currency';
import { AlertItem } from '@/store/state/alerts/alerts.reducer';
import { compareDesc, parseISO } from 'date-fns';

type AlertsByStatus = [liveAlerts: AlertItem[], historicalAlerts: AlertItem[]];

export const divideAlerts = (alerts: AlertItem[]) => {
  const filteredAlerts = alerts.reduce(
    (acc, alert) => {
      if (alert.status === 'Watched') {
        acc[0].push(alert);
      } else {
        acc[1].push(alert);
      }
      return acc;
    },
    [[], []] as AlertsByStatus,
  );
  filteredAlerts[0] = filteredAlerts[0].sort((a, b) => compareDesc(parseISO(a.creationDate), parseISO(b.creationDate)));
  filteredAlerts[1] = filteredAlerts[1].sort((a, b) => compareDesc(parseISO(a.updateTime), parseISO(b.updateTime)));
  return filteredAlerts;
};

export const getIsSameLimitPrice = (liveAlerts: AlertItem[], editedLimitPrice: number | null, baseCurrency?: Currency, contraCurrency?: Currency) => {
  const limitPricesByCurrencyPairAlreadyCreated = liveAlerts.reduce(
    (acc, alert) => {
      acc.push({ limitPrice: alert.limitPrice, currencyPair: alert.currencyPair });

      return acc;
    },
    [] as Array<{ limitPrice: number; currencyPair: string }>,
  );

  return limitPricesByCurrencyPairAlreadyCreated.some(
    ({ currencyPair, limitPrice }) =>
      (currencyPair === `${contraCurrency}/${baseCurrency}` || currencyPair === `${baseCurrency}/${contraCurrency}`) && limitPrice === editedLimitPrice,
  );
};
