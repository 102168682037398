import type { TradeCapture } from '@/models/tradeCapture';
import type { AppAction } from '@/store/app.actions';
import type { Reducer } from '@reduxjs/toolkit';

export const DEFAULT_TRADE_CAPTURE_STATE: TradeCapture = { status: null };

export const tradeCaptureReducer: Reducer<TradeCapture, AppAction> = (
  // biome-ignore lint/style/useDefaultParameterLast: will be fixed with RTK conversion
  state = DEFAULT_TRADE_CAPTURE_STATE,
  action,
): TradeCapture => {
  switch (action.type) {
    case 'ADD_TC_ERROR':
      return { status: 'unreachable' };
    case 'CLEAR_TC_ERROR':
      return { status: null };
    default:
      return state;
  }
};
