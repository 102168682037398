import { getApiUrl } from '@/config/app.config';
import { getFakeUser, getSgwtConnect, useFake } from '@/sgwtConnect';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const tradesApi = createApi({
  reducerPath: 'tradesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: getApiUrl(),
    prepareHeaders: (headers) => {
      headers.set('Authorization', getSgwtConnect().getAuthorizationHeader() ?? '');
      if (useFake) {
        headers.set('X-Fake-Authentication', getFakeUser());
      }
    },
  }),
  endpoints: (builder) => ({
    closedDates: builder.query<Record<string, string>, string>({
      query: (currencyPair) => ({
        url: `myfx/dates/closed`,
        method: 'POST',
        body: [currencyPair],
      }),
    }),
  }),
});

export const { useClosedDatesQuery } = tradesApi;
