import type { StreamingInfo } from '@/models/streaming';
import type { Reducer } from '@reduxjs/toolkit';
import type { StreamingAction } from './streaming.actions';

export const DEFAULT_STREAMING_STATE: Partial<StreamingInfo> = {};

export const streamingReducer: Reducer<Partial<StreamingInfo>, StreamingAction> = (
  // biome-ignore lint/style/useDefaultParameterLast: will be fixed with RTK conversion
  state = DEFAULT_STREAMING_STATE,
  action,
): Partial<StreamingInfo> => {
  switch (action.type) {
    case 'STREAMING_CONNECTED':
      return action;
    default:
      return state;
  }
};
