import type { Reducer } from '@reduxjs/toolkit';

import type { AppAction } from '@/store/app.actions';
import type { GrowlsState } from './growls.model';

export const DEFAULT_GROWLS_STATE: GrowlsState = {
  growls: [],
};

export const growlsReducer: Reducer<GrowlsState, AppAction> = (
  // biome-ignore lint/style/useDefaultParameterLast: will be fixed with RTK conversion
  state = DEFAULT_GROWLS_STATE,
  action,
): GrowlsState => {
  switch (action.type) {
    case 'AddGrowl':
      return {
        ...state,
        growls: [
          ...state.growls,
          {
            id: action.id,

            ...action.growl,
          },
        ],
      };
    case 'RemoveGrowl':
      return {
        ...state,
        growls: state.growls.filter((c) => c.id !== action.id),
      };
    default:
      return state;
  }
};
