import { isDev } from '@/config/app.config';
import type { TrackAction, TrackRfq, UpdateSelectedCompanyName } from '@/utils/useMatomo';
import { type Action, type Dispatch, type Middleware, configureStore, createDynamicMiddleware } from '@reduxjs/toolkit';
import { type TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { type ActionsObservable, type StateObservable, createEpicMiddleware } from 'redux-observable';
import type { Observable } from 'rxjs';
import type { AppAction } from './app.actions';
import { appEpic } from './app.epic';
import { rootReducer } from './app.reducers';
import type { AppState } from './app.state';
import { boostMailApi } from './boost-mail/boostMail.api';
import { logActionMiddleware } from './redux-middlewares/logActionMiddlewares';
import { matomoMiddleware } from './redux-middlewares/matomoMiddleware';
import { rememberLastActionsMiddleware } from './redux-middlewares/rememberLastActionsMiddleware';
import { autosendHelpRequestMiddleware } from './redux-middlewares/sendHelpRequestMiddleware';
import { crashHandlerMiddleware } from './redux-middlewares/tryCatchMiddleware';
import { tradesApi } from './state/trades/trades.api';
import { localeMiddleware } from './state/ui/locale.middleware';
import { upcomingFlowsApi } from './state/upcomingFlows/upcomingFlows.api';

export const createStore = (trackAction: TrackAction, trackRfq: TrackRfq, updateSelectedCompanyName: UpdateSelectedCompanyName, preloadedState = {}) => {
  const lastActions: Action[] = [];

  function getLastActions(): readonly Action[] {
    return [...lastActions].reverse();
  }

  const epicMiddleware = createEpicMiddleware<AppAction, AppAction, AppState>();
  const dynamicMiddleware = createDynamicMiddleware();

  const rtkStore = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        // TODO: enable serializableCheck
        // it was disable to avoid error with Date properties
        // see immutableCheck just above
        serializableCheck: false,
        immutableCheck: {
          // the matomo library provided by sgwtWidgets update the Date.prototype
          // by adding the function 'getTimeAlias'
          // Date would not be stored in redux
          // https://redux.js.org/tutorials/essentials/part-4-using-data#storing-dates-for-posts
          ignoredPaths: ['rfs.date.getTimeAlias', 'rfs.spotDate.getTimeAlias'],
        },
      })
        .prepend(crashHandlerMiddleware)
        .concat(dynamicMiddleware.middleware)
        .concat(logActionMiddleware)
        .concat(rememberLastActionsMiddleware(lastActions))
        .concat(matomoMiddleware(trackAction, trackRfq, updateSelectedCompanyName))
        .concat(localeMiddleware)
        .concat(epicMiddleware as Middleware)
        .concat(upcomingFlowsApi.middleware)
        .concat(tradesApi.middleware)
        .concat(boostMailApi.middleware),
    preloadedState: { ...preloadedState },
  });

  const finalStore = {
    ...rtkStore,
    getLastActions,
  };

  // necessary to avoid TS7022: rtkStore implicitly has type any because it does not have a type annotation and is referenced directly or indirectly in its own initializer.
  // if the middleware is added above
  // @ts-ignore
  dynamicMiddleware.addMiddleware(autosendHelpRequestMiddleware(isDev, () => finalStore));

  epicMiddleware.run(appEpic(trackRfq));

  return finalStore;
};

export type AppStore = ReturnType<typeof createStore>;
export type AppDispatch = Dispatch<AppAction>;
export const useAppDispatch: () => AppStore['dispatch'] = useDispatch;
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;

export interface DispatchExt {
  dispatch: AppDispatch;
}

export type Epic<Input extends Action = Action, Output = Input, State = unknown, Dependencies = unknown> = (
  action$: ActionsObservable<Input>,
  state$: StateObservable<State>,
  dependencies: Dependencies,
) => Observable<Output>;

export type EpicWithThunk = Epic<AppAction, AppAction, AppState, undefined>;
