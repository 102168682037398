import { getApiUrl } from '@/config/app.config';
import { getFakeUser, getSgwtConnect, useFake } from '@/sgwtConnect';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const boostMailApi = createApi({
  reducerPath: 'boostMailApi',
  baseQuery: fetchBaseQuery({
    baseUrl: getApiUrl(),
    prepareHeaders: (headers) => {
      headers.set('Authorization', getSgwtConnect().getAuthorizationHeader() ?? '');
      if (useFake) {
        headers.set('X-Fake-Authentication', getFakeUser());
      }
    },
  }),
  endpoints: (builder) => ({
    sendEmail: builder.query<string, { companyBdrId: string; companyName: string }>({
      query: ({ companyBdrId, companyName }) => ({
        url: `myfx/email/boost`,
        method: 'POST',
        body: { companyBdrId, companyName },
      }),
    }),
  }),
});

export const { useLazySendEmailQuery } = boostMailApi;
