import html2canvas from "html2canvas";

function shouldIgnoreElement(element: Element): boolean {
  const { classList } = element;
  return Boolean(
    (classList &&
      (classList.contains('life-ring-modal-component') ||
        classList.contains('modal') ||
        classList.contains('modal-backdrop'))) ||
    element.getAttribute('role') === 'dialog',
  );
}

export function takeScreenshot(element = document.body): Promise<HTMLCanvasElement> {
  return html2canvas(element, {
    logging: true,
    ignoreElements: (htmlElement): boolean => {
      if (htmlElement instanceof HTMLImageElement && htmlElement.src.toLowerCase().includes('piwik')) {
        return true;
      }

      return shouldIgnoreElement(htmlElement);
    },
  });
}