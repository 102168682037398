import { SgPicker } from '@/App/Shared/SgPicker';
import { useFormattingContext } from '@/utils/format';
import { useCallback, useEffect, useState } from 'react';

interface TradesFilterProps<T extends string | number> {
  currentSelection?: T[];
  options: [string, T][];
  label: string;
  onChange?: (filter: T[]) => void;
  labelE2e?: string;
}

export function TradesFilter<T extends string | number>({ currentSelection, options, label, onChange, labelE2e }: TradesFilterProps<T>) {
  const { formatMessage } = useFormattingContext();
  const [selection, setSelection] = useState(currentSelection ?? []);

  useEffect(() => setSelection(currentSelection ?? []), [currentSelection]);

  const onInnerChange = useCallback(
    (items: T[]) => {
      if (onChange) onChange(items);
    },
    [onChange],
  );

  const items = options.map(([lbl, key]) => ({
    key,
    label: lbl,
    selected: selection.includes(key),
  }));

  return (
    <div>
      <label htmlFor="exampleFormControlTextarea1">{label}</label>

      <SgPicker items={items} onChange={onInnerChange} placeholder={formatMessage('trades.toolbar.filters.placeholder')} keepOrder size="md" noIcon labelE2e={labelE2e} />
    </div>
  );
}
