import { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { getFieldsByInstrument, resetCalendar } from './state';
import { AccumulatorTrade } from '@/models/trade';
import DispatchContext from './CalendarContext';
import { exportCalendar } from '../Trades/TradesList/helpers';
import { CalendarFields } from '@/models/calendar';
import { getComputeDisplayFields } from './getComputeDisplayField';
import { formatDateLocalized } from '@/utils/locale';
import { parseISO } from 'date-fns';
import { DATE_LONG_FORMAT } from '@/utils/format';
import { isDefined } from '@sgme/fp';

interface CalendarSubHeaderProps {
  trade: AccumulatorTrade;
  editingRowsId: number[];
  badges: string[];
}

export function CalendarSubHeader({ trade, editingRowsId, badges }: CalendarSubHeaderProps) {
  const { dispatch } = useContext(DispatchContext);
  const intl = useIntl();
  const startDate = isDefined(trade.strikeRanges?.[0].beginDate) && formatDateLocalized(parseISO(trade.strikeRanges?.[0].beginDate!), DATE_LONG_FORMAT);
  const endDate = isDefined(trade.strikeRanges?.[0].endDate) && formatDateLocalized(parseISO(trade.strikeRanges?.[0].endDate!), DATE_LONG_FORMAT);

  const computedFields = getComputeDisplayFields(getFieldsByInstrument(trade), trade, badges, intl.locale);

  const handleReset = () => {
    dispatch(resetCalendar(trade));
  };

  return (
    <div
      className="sticky-top bg-lvl1 border-primary-alt border-start border-end"
      style={{
        top: '40px',
      }}
    >
      <h2 className="mb-1">
        <FormattedMessage id="trades.tradeRow.calendar" />
      </h2>
      {editingRowsId && editingRowsId?.length > 0 && (
        <span className="badge rounded-pill badge-light ms-3 bg-info text-white" data-e2e="simulation-badge">
          <FormattedMessage id="app.calendar.fixingInfos.isSimulating" />
        </span>
      )}
      <div className="d-flex">
        {isDefined(trade.strikeRanges) && (
          <div className="mt-1" data-e2e="TimeInterval">
            <FormattedMessage
              id="app.calendar.fixingInfos.TimeInterval"
              values={{
                startDate,
                endDate,
                bold: (chunks) => <strong>{chunks}</strong>,
              }}
            />
          </div>
        )}
      </div>
      <div className="d-flex justify-content-between">
        <div>
          <p className="text-regular fw-medium mt-2 text-info mb-2">
            <FormattedMessage id="app.calendar.fixingInfos" />
          </p>
        </div>
        <div className="d-flex justify-content-center">
          {editingRowsId && editingRowsId?.length > 0 && (
            <div>
              <button type="button" className="btn btn-link btn-lg p-0 text-info" onClick={handleReset}>
                <i className="icon icon-xs me-2 ps-2" data-e2e="reset">
                  restore
                </i>
                <FormattedMessage id="app.calendar.fixingInfos.reset" />
              </button>
            </div>
          )}
          <div>
            <button type="button" className="btn btn-link btn-lg p-0" onClick={() => exportCalendar<CalendarFields>(computedFields, trade, intl)}>
              <i className="icon icon-xs me-2 ps-4">save_alt</i>
              <FormattedMessage id="trades.tradesViewer.export" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
