import type { ForwardTrade } from '@/models/trade';
import type { AppAction } from '@/store/app.actions';
import type { Reducer } from '@reduxjs/toolkit';

export interface PredeliverState {
  trade: ForwardTrade | undefined;
}

export const DEFAULT_PREDELIVER_STATE: PredeliverState = { trade: undefined };

export const predeliverReducer: Reducer<PredeliverState, AppAction> = (
  // biome-ignore lint/style/useDefaultParameterLast: will be fixed with RTK conversion
  state = DEFAULT_PREDELIVER_STATE,
  action,
): PredeliverState => {
  switch (action.type) {
    case 'START_PREDELIVER':
      return { trade: action.trade };

    case 'RESET':
      return { trade: undefined };

    default:
      return state;
  }
};
