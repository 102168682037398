import { blockExecutionThunk } from '@/store/state/ui/ui.thunks';
import { SgwtHelpCenter, useSgwtWidgets } from '@sgwt/sgwt-widgets-react';
import { useCallback, useEffect } from 'react';
import { useAppDispatch } from '@/store/store';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'sgwt-help-center': any;
    }
  }
}

export const HelpCenterId = 'my-help-center';

export interface HelpCenterApi extends Element {
  open: () => void;
  setDefaultSendTo: (sendTo: string) => void;
}
const helpCenterQuery = `#${HelpCenterId}`;
export const getHelpCenter = () => document.querySelector<HelpCenterApi>(helpCenterQuery)!;

export function HelpCenter() {
  const dispatch = useAppDispatch();
  const { sgwtHelpCenter } = useSgwtWidgets();

  const onToggle = useCallback((blockExecution: boolean) => {
    dispatch(blockExecutionThunk(blockExecution));
  }, [dispatch]);


  useEffect(() => {
    const onHelpOpen = () => onToggle(true);
    const onHelpClose = () => onToggle(true);

    sgwtHelpCenter?.addEventListener('sgwt-help-center--open', onHelpOpen);

    sgwtHelpCenter?.addEventListener('sgwt-help-center--close', onHelpClose);

    return () => {
      sgwtHelpCenter?.removeEventListener('sgwt-help-center--open', onHelpOpen);
      sgwtHelpCenter?.removeEventListener('sgwt-help-center--close',  onHelpClose);
    };
  }, [sgwtHelpCenter, onToggle]);

  return (
    <aside>
      <SgwtHelpCenter id={HelpCenterId} mailSubject={`[SGME-MYFX:${window.sgmeConfiguration.env}] Help request message`} language="fr" applicationId="sgmarkets-myfx" messageOnly allowScreenshot />
    </aside>
  );
}

HelpCenter.displayName = 'HelpCenter';
