import { useMatch } from 'react-router';
import { AccountSelector } from './AccountSelector/AccountSelector';
import { Growls } from './Growls/Growls';
import { Title } from './Header/Title';
import { PortfolioTabs } from './PortfolioTabs';
import { routes } from './routes';

export function Header2() {
  const inPortfolio = useMatch(routes.PORTFOLIO);
  const inUpcommingFlow = useMatch(routes.UPCOMING_FLOWS);
  const inHome = useMatch(routes.HOME);
  const inCalendar = useMatch(routes.CALENDAR);
  const inRollOver = useMatch(routes.REPORT_ENVELOPPE);
  const inPredeliver = useMatch(routes.USE_ENVELOPPE);

  if (inCalendar || inPredeliver || inRollOver) {
    return null;
  }

  return (
    <div className=" mb-0 pb-0">
      <div className="container-xxl header2 position-relative">
        <Growls />

        {inHome ? <div className="spacing-m-2" /> : null}

        <div className="d-md-flex pb-3">
          <Title />
          <div className="buttons">
            <AccountSelector />
          </div>
        </div>
      </div>

      <div className="border-bottom">
        <div className="container-xxl">{(inPortfolio || inUpcommingFlow) && <PortfolioTabs />}</div>
      </div>
    </div>
  );
}
