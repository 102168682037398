import { SgwtMiniFooter } from '@sgwt/sgwt-widgets-react';
import type * as React from 'react';

interface FooterHTMLAttributes {
  id: string;
  mode?: 'sg-markets';
  'contact-us': string;
  container?: string;
  'cookies-consent'?: boolean;
  'accessibility-compliance'?: 'none' | 'partial' | 'full';
  'accessibility-link'?: string;
}

export function Footer() {
  return (
    <SgwtMiniFooter
      container="container"
      id="sgwt-mini-footer"
      mode="sg-markets"
      contactUs={{ mail: 'sgmarketsfx@sgcib.com' }}
      cookiesConsent
      accessibilityCompliance="none"
      accessibilityLink="https://shared.sgmarkets.com/accessibility/statement/en.html?redirect_uri=https://myfx.sgmarkets.com"
    />
  );
}
